import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IConfig, NgxMaskModule } from 'ngx-mask';

import { TranslateModule } from '@ngx-translate/core';

import { LogoComponent } from './helpers/logo/logo.component';
import { OPTIONS_TOKEN } from '@fi-sas/configurator';
import { WP_CONFIGURATION } from './../app.config';

import {
  DataUnitsPipe,
  EuroCurrenyPipe,
  HasPermissionPipe,
  HourConvertPipe,
  LanguageChangePipe,
  LocalizedDatePipe,
  ReplacePipe,
  YesOrNoPipe,
} from './pipes';
import { GenderBeautifyPipe } from './pipes/gender-beautify.pipe';
import { GetFromObjectsArrayPipe } from './pipes/get-from-objects-array.pipe';
import { NZ_I18N, pt_PT } from 'ng-zorro-antd/i18n';
import { ViewTermsComponent } from './components/view-terms/view-terms.component';
import { PaymentComponentComponent } from '../modules/current-account/components/payment-component/payment-component.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';


export let options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    DataUnitsPipe,
    EuroCurrenyPipe,
    GenderBeautifyPipe,
    GetFromObjectsArrayPipe,
    HasPermissionPipe,
    HourConvertPipe,
    LanguageChangePipe,
    LocalizedDatePipe,
    ReplacePipe,
    YesOrNoPipe,
    // VER ESTE
    LogoComponent,
    ViewTermsComponent,
    PaymentComponentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    //VER ESTE
    NgxMaskModule.forRoot(options),
    ReactiveFormsModule,
    RouterModule,
    NzGridModule,
    NzModalModule,
    NzProgressModule,
    NzInputModule,
    NzSelectModule,
    NzButtonModule,
    NzIconModule,
    NzInputNumberModule,
    TranslateModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-Pt' },
    { provide: NZ_I18N, useValue: pt_PT },
    { provide: OPTIONS_TOKEN, useValue: WP_CONFIGURATION },
  ],
  exports: [
    DataUnitsPipe,
    EuroCurrenyPipe,
    FormsModule,
    GenderBeautifyPipe,
    GetFromObjectsArrayPipe,
    HasPermissionPipe,
    HourConvertPipe,
    HttpClientModule,
    LanguageChangePipe,
    LocalizedDatePipe,
    LogoComponent,
    NgxMaskModule,
    ReactiveFormsModule,
    ReplacePipe,
    TranslateModule,
    YesOrNoPipe,
    PaymentComponentComponent
  ],
  entryComponents: [],
})
export class SharedModule { }

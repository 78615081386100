import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  NzMessageDataOptions,
  NzMessageRef,
  NzMessageService
} from 'ng-zorro-antd/message';
import { SiderItem } from '../models/sider-item';
import { SidebarService } from '../components/layout-slide-menu/sidebar-items';

export enum MessageType {
  success,
  info,
  warning,
  error,
  loading
}

@Injectable({
  providedIn: 'root'
})
export class UiService {

  siderDrawerCollapsed = true;
  siderDrawerCollapsedSubject = new BehaviorSubject<boolean>(this.siderDrawerCollapsed);
  siderCollapsed = true;
  siderCollapsedSubject = new BehaviorSubject<boolean>(this.siderCollapsed);

  siderItems: SiderItem[] = [];
  siderItemsSubject: BehaviorSubject<SiderItem[]> = new BehaviorSubject<SiderItem[]>([]);

  constructor(
    private messageService: NzMessageService,
    private sidebarService: SidebarService
  ) {
    this.getSiderItems();
    this.siderItemsSubject.next(this.siderItems);
  }

  triggerSider() {
    this.siderCollapsed = !this.siderCollapsed;
    this.emitteSider();
  }

  triggerDrawerSider() {
    this.siderDrawerCollapsed = !this.siderDrawerCollapsed;
    this.emitteDrawerSider();
  }

  disableSideOption(disable: boolean, option: string, subOption?: string): SiderItem[] {

    if (subOption) {
      const parent = this.siderItems.find(item => item.code === option)
      if (parent && parent.children) {
        const child = parent.children.find(subItem => subItem.code === subOption);
        if (child) {
          child.disable = disable;
        }
      }
    } else {
      const item = this.siderItems.find(item => item.code === option);
      if (item)
        item.disable = disable;
    }
    this.emmitteSliderItems();
    return this.siderItems;
  }

  private emitteSider() {
    this.siderCollapsedSubject.next(this.siderCollapsed);
  }
  private emitteDrawerSider() {
    this.siderDrawerCollapsedSubject.next(this.siderDrawerCollapsed);
  }

  siderObservable(): Observable<boolean> {
    return this.siderCollapsedSubject.asObservable();
  }

  siderDrawerObservable(): Observable<boolean> {
    return this.siderDrawerCollapsedSubject.asObservable();
  }

  /***
   * Show a message on top of screen
   */
  showMessage(
    type: MessageType,
    message: string,
    config?: NzMessageDataOptions
  ): NzMessageRef {
    switch (type) {
      case MessageType.success:
        return this.messageService.success(message);
      case MessageType.info:
        return this.messageService.info(message);
      case MessageType.warning:
        return this.messageService.warning(message);
      case MessageType.error:
        return this.messageService.error(message);
      case MessageType.loading:
        return this.messageService.loading(message);
    }
  }

  /***
   * Remove a message from the stack
   */
  removeMessage(message: NzMessageRef) {
    this.messageService.remove(message.messageId);
  }

  setSiderItems(items: SiderItem[]): SiderItem[] {
    this.siderItems = items;
    this.emmitteSliderItems();
    return this.siderItems;
  }

  /***
   * Add a item to the side menu
   */
  addSiderItem(item: SiderItem): SiderItem[] {
    this.siderItems.push(item);
    this.emmitteSliderItems();
    return this.siderItems;
  }

  /***
   * Remove all the items from the side nav menu
   */
  removeSiderItems(): SiderItem[] {
    this.siderItems = [];
    this.emmitteSliderItems();
    return this.siderItems;
  }

  /***
   * Remove the item send by parameter from the side nav menu
   */
  removeSiderItem(item: SiderItem): SiderItem[] {
    const index = this.siderItems.indexOf(item, 0);
    if (index > -1) {
      this.siderItems.splice(index, 1);
    }
    this.emmitteSliderItems();
    return this.siderItems;
  }

  /***
   * Retunrs de the items on the side nav menu
   */
  getSiderItems(): SiderItem[] {
    // return this.siderItems;
    this.siderItems = [];
    this.sidebarService.getSideBarItems().forEach((item) => {
      this.siderItems.push(Object.assign({}, item));
    })
    return this.siderItems;
  }

  /***
   * Returns the observable of the side nav menu items
   */
  getSiderItemsObservable(): Observable<SiderItem[]> {
    return this.siderItemsSubject.asObservable();
  }

  private emmitteSliderItems() {
    this.siderItemsSubject.next(this.siderItems);
  }
}


import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Resource } from '@fi-sas/core';

import { first, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ConfigurationsService } from './shared/services/configurations.service';

@Injectable()
export class AppResolver
  implements Resolve<Observable<Resource<any>>> {
  constructor(private configurationGeralService: ConfigurationsService) {}

  resolve() {
    localStorage.removeItem("configuraction_active_menu_wp");
    return this.configurationGeralService.geral().pipe(
      first(), 
      tap(results => {
        localStorage.setItem("configuraction_active_menu_wp", JSON.stringify(results.data[0].services));
        })
  );
  }
}

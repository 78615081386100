import { FiResourceService, FiUrlService, Resource } from '@fi-sas/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { CartModel, ItemModel } from '@fi-sas/webpage/modules/payments/models/cart.model';
import { finalize, first } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { CartCheckoutModel } from '@fi-sas/webpage/modules/payments/models/cart-checkout.model';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  account_id: number;
  cartData: any = [];

  cartTotalPrice$ = new BehaviorSubject<number>(0);
  cartTotal$ = new BehaviorSubject<number>(0);
  cartData$ = new BehaviorSubject([]);

  constructor(
    private resourceService: FiResourceService,
    private urlService: FiUrlService
  ) {
    this.getDataNotification();
  }

  getAllProducts(): Observable<Resource<any>> {
    return this.resourceService.list<any>(this.urlService.get('PAYMENTS.CART', {}));
  }

  clearCart(id_account: string): Observable<Resource<ItemModel>> {
    const body = {
      account_id: id_account,
    };
    return this.resourceService.create<ItemModel>(
      this.urlService.get('PAYMENTS.CART_CHECKOUT', {}),
      body
    );
  }

  changeItemQuantitie(id_account: string): Observable<Resource<ItemModel>> {
    const body = {
      account_id: id_account,
    };
    return this.resourceService.create<ItemModel>(
      this.urlService.get('PAYMENTS.CART', {}),
      body
    );
  }

  removeItemsCart(id: number) {
    let body: any = {};
    body.account_id = id;
    return this.resourceService.create<ItemModel>(
      this.urlService.get('PAYMENTS.CLEAR', {}),
      body
    );
  }

  /**
 * removes an item from the cart
 * @param id - item id
 */
   removeItemCart(id) {
    const params = new HttpParams()
      .set('item_id', id.toString());
    return this.resourceService.delete(
      this.urlService.get('PAYMENTS.REMOVE_ITEM'), { params }
    );
  }

  changeQtdItem(item_id: string, quantity: number): Observable<Resource<ItemModel>> {
    let body: any = {};
    body.item_id = item_id;
    body.quantity = quantity;
    return this.resourceService.update<ItemModel>(
      this.urlService.get('PAYMENTS.CART_ITEM_QUANTITY', {}),
      body
    );
  }

  checkoutCart(id_item: string, qtt: number): Observable<Resource<ItemModel>> {
    const body = {
      item_id: id_item,
      quantity: qtt,
    };
    return this.resourceService.create<ItemModel>(
      this.urlService.get('PAYMENTS.CART_CHECKOUT', {}),
      body
    );
  }

  getCartById(id: number): Observable<Resource<any>> {
    return this.resourceService.list<any>(
      this.urlService.get('PAYMENTS.CART') + '?query[account_id]=' + id
    );

  }

  addToCartAlimentation(id: number): Observable<Resource<any>> {
    return this.resourceService.create<any>(
      this.urlService.get('ALIMENTATION.CART_ADD_ALIMENTATION', { id }),
      {}
    );

  }

  addToCartRefillH2O(id: number): Observable<Resource<any>> {
    return this.resourceService.create<any>(
      this.urlService.get('REFILL_H2O.MENU.ADD_CART', { id }),
      {}
    );

  }


  checkout(id) {
    const body = {
      account_id: id
    };
    return this.resourceService.update<any>(
      this.urlService.get('PAYMENTS.CART_CHECKOUT'),
      body
    );
  }

  getDataNotification(): void {
    this.getAllProducts()
      .pipe(
        first(),
        finalize(() => { })
      )
      .subscribe(
        (result) => {
          let total = 0;
          let listData = [];
          let finalPrice = 0;
          for (const cart of result.data) {
           
           // total += cart.items.length;
            for (const item of cart.items) {
              listData.push(item);
              finalPrice += item.total_value;
              total += item.quantity;
            }
          }
          this.cartTotalPrice$.next(finalPrice);
          this.cartData$.next(result.data);
          this.cartTotal$.next(total);
        },
        (error) => { }
      );
  }


    createCart(cart: CartModel): Observable<Resource<CartModel>> {
    return this.resourceService.create<CartModel>(this.urlService.get('PAYMENTS.CART', {}), cart);
    }

    cartCheckout(id: number, checkout: CartCheckoutModel): Observable<Resource<CartCheckoutModel>> {
    return this.resourceService.create<CartCheckoutModel>(this.urlService.get('PAYMENTS.CART_CHECKOUT', { id }), checkout);
    }

    cartCheckoutItems(checkout: any): Observable<Resource<CartCheckoutModel>> {
    return this.resourceService.create<CartCheckoutModel>(this.urlService.get('PAYMENTS.CART_CHECKOUT'), checkout);
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FiResponsiveBreakpointDirective,
  RESPONSIVE_BREAKPOINTS_TOKEN,
  DEFAULT_RESPONSIVE_BREAKPOINTS_TOKEN
} from './responsive.directive';
import { FiPriorityService } from './priority.service';

@NgModule({
  imports: [CommonModule, FormsModule, HttpClientModule, ReactiveFormsModule],
  providers: [
    {
      provide: RESPONSIVE_BREAKPOINTS_TOKEN,
      useValue: DEFAULT_RESPONSIVE_BREAKPOINTS_TOKEN
    },
    { provide: FiPriorityService, useClass: FiPriorityService }
  ],
  declarations: [FiResponsiveBreakpointDirective],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FiResponsiveBreakpointDirective
  ]
})
export class FiShareModule {}

import { Pipe } from '@angular/core';

import { GenderOptions } from '../data/gender';
import { Gender } from '../models';
import { GetFromObjectsArrayPipe } from './get-from-objects-array.pipe';

@Pipe({
  name: 'genderBeautify'
})
export class GenderBeautifyPipe extends GetFromObjectsArrayPipe {
  override transform(key: Gender): string {
    return super.transform(key, 'value', GenderOptions, 'label');
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@fi-sas/webpage/auth/services/auth.service';

@Pipe({
    name: 'hasPermission'
})
export class HasPermissionPipe implements PipeTransform {

    constructor(private authservice: AuthService) { }

    transform(value: string): boolean {
        return this.authservice.hasPermission(value);
    }

}
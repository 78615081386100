export enum NotificationStatus {
    SUCCESS = 'SUCCESS',
    PENDING = 'PENDING',
    ERROR = 'ERROR'
}

export class NotificationModel {
    id: number;
    alert_id: number;
    notification_method_id: number;
    subject: string;
    message: string;
    status: NotificationStatus;
    error_message: string;
    updated_at: Date;
    created_at: Date;
    trial: number;
    unread: boolean;
    total_unread: number;
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { FiConfigurator } from '@fi-sas/configurator';
import { filter, map, throwIfEmpty, catchError } from 'rxjs/operators';

export type RESOURCE_STATUS = 'success' | 'error' | 'fail';

export interface Link {
  self: string;
  prev: string;
  next: string;
  first: string;
  last: string;
  total: number;
}

export interface ResourceError {
  code: string;
  message: string;
  field: string;
  index: number;
}

export interface Resource<Model> {
  status: RESOURCE_STATUS;
  link: Link;
  data: Model[];
  errors: ResourceError[];
}

export interface HttpOptions {
  headers?:
  | HttpHeaders
  | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?:
  | HttpParams
  | {
    [param: string]: string | string[];
  };
  withCredentials?: boolean;
}

@Injectable()
export class FiResourceService {
  constructor(
    protected http: HttpClient,
    protected configurator: FiConfigurator
  ) { }

  /**
   * Method to get one single record from response.
   *
   * @param {string} endpoint
   * @param {HttpOptions} options
   */
  public read<M>(
    endpoint: string,
    options?: HttpOptions
  ): Observable<Resource<M>> {
    return this.http.get<Resource<M>>(endpoint, options).pipe(
      filter(item => this._checkSignature(item)),
      throwIfEmpty(() => new Error('Response signature is invalid')),
      map((item: Resource<M>) => {
        const data: M[] = [];
        item.data = data.concat(item.data && item.data[0] ? item.data[0] : []);
        return item;
      })
    );
  }

  /**
   * Method to get array list.
   *
   * @param {string} endpoint
   * @param {HttpOptions} options
   */
  public list<M>(
    endpoint: string,
    options?: HttpOptions
  ): Observable<Resource<M>> {
    return this.http.get<Resource<M>>(endpoint, options).pipe(
      filter(item => this._checkSignature(item)),
      throwIfEmpty(() => new Error('Response signature is invalid')),
      map(item => {
        const data: M[] = [];
        item.data = data.concat(item.data ? item.data : []);
        return item;
      })
    );
  }

  /***
   * Method to create a entity and return first entity from response
   * @param endpoint
   * @param body
   * @param options
   */
  public create<M>(
    endpoint: string,
    body: Object,
    options?: HttpOptions
  ): Observable<Resource<M>> {
    return this.http.post<Resource<M>>(endpoint, body, options).pipe(
      filter(item => this._checkSignature(item)),
      throwIfEmpty(() => new Error('Response signature is invalid')),
      map(item => {
        const data: M[] = [];
        item.data = data.concat(item.data ? item.data : []);
        return item;
      })
    );
  }

  /***
   * * Method to update a entity and return first entity from response
   * @param endpoint
   * @param body
   * @param options
   */
  public update<M>(
    endpoint: string,
    body: Object,
    options?: HttpOptions
  ): Observable<Resource<M>> {
    return this.http.put<Resource<M>>(endpoint, body, options).pipe(
      filter(item => this._checkSignature(item)),
      throwIfEmpty(() => new Error('Response signature is invalid')),
      map(item => {
        const data: M[] = [];
        item.data = data.concat(item.data ? item.data : []);
        return item;
      })
    );
  }

  /***
   * * Method to patch a entity and return entitis from response
   * @param endpoint
   * @param body
   * @param options
   */
  public patch<M>(
    endpoint: string,
    body: Object,
    options?: HttpOptions
  ): Observable<Resource<M>> {
    return this.http.patch<Resource<M>>(endpoint, body, options).pipe(
      filter(item => this._checkSignature(item)),
      throwIfEmpty(() => new Error('Response signature is invalid')),
      map(item => {
        const data: M[] = [];
        item.data = data.concat(item.data ? item.data : []);
        return item;
      })
    );
  }

  public delete(endpoint: string, options?: HttpOptions) {
    return this.http.delete(endpoint, options);
  }

  public request(method: string, url: string, options: HttpOptions = {}) {
    return this.http.request(method, url, options);
  }

  /**
   * Method to check response signature.
   *
   * @param {Resource} resource
   * @returns boolean
   */
  private _checkSignature(resource: Resource<any>): boolean {
    const { keys } = Object;
    const signaturaKeys = ['status', 'link', 'data', 'errors'];

    const isIn = (key: string) => signaturaKeys.includes(key);

    return !isEmpty(resource) && keys(resource).every(isIn);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { YesOrNoModel } from '@fi-sas/webpage/shared/models/yes-or-no.model';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'yesOrNo'
})

export class YesOrNoPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: any, text: YesOrNoModel): any {
    return value ? this.translateService.instant(text.yes) : this.translateService.instant(text.no);
  }

}


import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FiUrlService, FiResourceService, Resource } from '@fi-sas/core';
import { AuthService } from '@fi-sas/webpage/auth/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { NotificationModel } from '../models/notifications.model';


@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  total$ = new BehaviorSubject<number>(0);
  notifications: NotificationModel[] = [];
  notificationsData = new BehaviorSubject([]);
  notificationsLength = new BehaviorSubject(0);
  page = 1;
  pageSize = 7;
  length = 0;

  constructor(private resourceService: FiResourceService,
    private urlService: FiUrlService, private authService: AuthService) {
    this.authService.getIsLoggedObservable().subscribe((isLogged) => {
      if (!isLogged) {
        this.notifications = [];
        this.length = 0;
        this.notificationsData.next(this.notifications);
        this.notificationsLength.next(this.length);
      } else {
        this.loadData();
      }
    })

  }

  list(): Observable<Resource<NotificationModel>> {
    let params = new HttpParams();
    params = params.set('offset', ((this.page - 1) * this.pageSize).toString());
    params = params.set('limit', this.pageSize.toString());
    return this.resourceService.list<NotificationModel>(this.urlService.get('NOTIFICATIONS.NOTIFICATIONS_INTERNAL', {}), { params });
  }

  loadData(increment?: boolean) {
    let total = 0;
    increment ? this.page++ : this.page = 1;
    this.list().pipe(
      first()
    )
      .subscribe((result) => {
        this.notifications = increment ? [...this.notifications, ...result.data] : result.data;
        this.length = result.link.total;
        if (this.notifications.length > 0) {
          total = this.notifications[this.notifications.length - 1].total_unread;
        }
        this.total$.next(total);
        this.notificationsData.next(this.notifications);
        this.notificationsLength.next(this.length);


      });

  }

  decrementTotal() {
    this.total$.next(this.total$.value - 1);
  }

  getNotifications() {
    return this.notifications;
  }

  getNotificationsObservable(): Observable<object> {
    return this.notificationsData.asObservable();
  }

  getNotificationsLengthObservable() {
    return this.notificationsLength.asObservable();
  }

  updateReadNotification(id: number): Observable<Resource<NotificationModel>> {
    return this.resourceService.update<NotificationModel>(this.urlService.get('NOTIFICATIONS.NOTIFICATIONS_READ', { id }), {});
  }

  delete(id: number) {
    return this.resourceService.delete(this.urlService.get('NOTIFICATIONS.NOTIFICATIONS_ID', { id }));
  }

}

import { Data } from './../interfaces/store';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'unit'
})
export class DataUnitsPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(value: any, args?: any): any {

    if(!value) {
      return '-';
    }

    if ( value.length === 0 || args === undefined ) {
      return value;
    }

    const numValeu: number = Number(value) ;
    const unit: string = args;

    switch (unit) {
      case 'g': {
        return this.unitFixed(unit, numValeu);
        break;
      }
      case 'cal': {
        return this.unitFixed(unit, numValeu);
        break;
      }
      case 'm': {
        return this.unitFixed(unit, numValeu);
        break;
      }
      case 'W': {
        return this.unitFixed(unit, numValeu);
        break;
      }
      case 'min': {
        return this.unitHour(unit, numValeu);
        break;
      }
      default: {
        const res: any = parseFloat(numValeu.toFixed(2)).toLocaleString('pt-PT') + '<span class="data-unit"> ' + unit + '</span>';
        return this.sanitizer.bypassSecurityTrustHtml(res);
        break;
      }
    }
  }

  /**
   * Add units and fixed value to Locale pt-PT
   * @param unit unit parameter
   * @param numValeu valeu parameter
   */
  unitFixed(unit: string, numValeu: number): any {

    if ( numValeu >= 1000 ) {
      numValeu = numValeu / 1000;
      const finalValue: string = parseFloat(numValeu.toFixed(2)).toLocaleString('pt-PT');
      const htmlRes: any = finalValue + '<span class="data-unit"> k' + unit + '</span>';
      return this.sanitizer.bypassSecurityTrustHtml(htmlRes);
    } else {
      const htmlRes: any = parseFloat(numValeu.toFixed(2)).toLocaleString('pt-PT') + '<span class="data-unit"> ' + unit + '</span>';
      return this.sanitizer.bypassSecurityTrustHtml(htmlRes);
    }

  }

  unitHour(unit: string, numValeu: number): any {

    if ( unit === 'min' ) {
      const hours: number = numValeu / 60;
      const rhours: number = Math.floor(hours);
      const minutes: number =  (hours - rhours) * 60;
      const rminutes: number =  Math.round(minutes);

      const reshours: string = ('00' + rhours).slice(-2);
      const resminutes: string = ('00' + rminutes).slice(-2);

      const htmlRes: any =  reshours + ':' + resminutes + '<span class="data-unit"> h</span>';
      return this.sanitizer.bypassSecurityTrustHtml(htmlRes);

    } else {
      const htmlRes: any = numValeu;
      return this.sanitizer.bypassSecurityTrustHtml(htmlRes);
    }
  }

}



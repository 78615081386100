import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface StorageValueType {
  [key: string]: any;
}

export type FiStorageAction = 'add' | 'remove' | 'reset';

@Injectable()
export class FiStorageService {
  public change = new Subject<{
    action: FiStorageAction;
    item: StorageValueType | null;
  }>();

  private _storage: typeof window.localStorage;

  constructor() {
    this._storage = window.localStorage;
  }

  get<T>(key: string, defaults = null): T | null {
    if (this.has(key)) {
      const item = this._storage.getItem(key);
      if (item) {
        return JSON.parse(item);
      } else {
        return defaults
      }
    }
    return defaults;
  }

  set(key: string, value: any): void {
    this._storage.setItem(key, JSON.stringify(value));

    this.change.next({ action: 'add', item: { key: key, value: value } });
  }

  has(key: string): boolean {
    return !!this._storage.getItem(key);
  }

  remove(key: string): void {
    this._storage.removeItem(key);

    this.change.next({ action: 'remove', item: { key: key, value: null } });
  }

  reset() {
    this._storage.clear();

    this.change.next({ action: 'reset', item: null });
  }

  size() {
    return this._storage.length;
  }
}

import { filter } from 'rxjs/operators';
import { Breadcrumb } from './../../models/breadcrumb';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { ConfigurationsService } from '@fi-sas/webpage/shared/services/configurations.service';

@Component({
  selector: 'app-layout-breadcrumb',
  templateUrl: './layout-breadcrumb.component.html',
  styleUrls: ['./layout-breadcrumb.component.less']
})
export class LayoutBreadcrumbComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';

  breadcrumbs: Breadcrumb[] = [];
  data;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private configurationsService: ConfigurationsService) {
    this.data = this.configurationsService.getLocalStorageTotalConfiguration();
  }

  ngOnInit() {

    // subscribe to the NavigationEnd event
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.updateData();
      });

    this.updateData();

  }

  private updateData() {
    const root: ActivatedRoute = this.activatedRoute.root;

    // set breadcrumbs
    this.breadcrumbs = this.getBreadcrumbs(root);
  }

  private getBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (
        !child.snapshot.data.hasOwnProperty(
          LayoutBreadcrumbComponent.ROUTE_DATA_BREADCRUMB
        )
      ) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url
        .map(segment => segment.path)
        .join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      if (
        child.snapshot.data[LayoutBreadcrumbComponent.ROUTE_DATA_BREADCRUMB] !==
        null
      ) {
        // add breadcrumb
        const breadcrumb: Breadcrumb = {
          label:
            child.snapshot.data[
            LayoutBreadcrumbComponent.ROUTE_DATA_BREADCRUMB
            ],
          params: child.snapshot.params,
          url
        };
        breadcrumbs.push(breadcrumb);

        breadcrumbs.forEach(element => {
          if (element.label == "DASHBOARD.BUTTON.SOCIAL_SUPPORT") {
            element.label = this.validTitleTraductions(17);
            element.type = 'array';
          } else if (element.label == "QUEUE.TITLE_QUEUE") {
            element.label = this.validTitleTraductions(21);
            element.type = 'array';
          } else if (element.label == "DASHBOARD.BUTTON.VOLUNTEERING") {
            element.label = this.validTitleTraductions(28);
            element.type = 'array';
          } else if (element.label == "DASHBOARD.BUTTON.CURRENT_ACCOUNT") {
            element.label = this.validTitleTraductions(11);
            element.type = 'array';
          } else if (element.label == "DASHBOARD.BUTTON.UBIKE") {
            element.label = this.validTitleTraductions(13);
            element.type = 'array';
          } else if (element.label == "DASHBOARD.BUTTON.MOBILITY") {
            element.label = this.validTitleTraductions(3);
            element.type = 'array';
          } else if (element.label == "DASHBOARD.BUTTON.ACCOMMODATION") {
            element.label = this.validTitleTraductions(1);
            element.type = 'array';
          } else if (element.label == "DASHBOARD.BUTTON.ALIMENTATION") {
            element.label = this.validTitleTraductions(2);
            element.type = 'array';
          } else if (element.label == "DASHBOARD.BUTTON.PRIVATEACCOMMODATION") {
            element.label = this.validTitleTraductions(15);
            element.type = 'array';
          } else if (element.label == "DASHBOARD.BUTTON.HEALTH") {
            element.label = this.validTitleTraductions(7);
            element.type = 'array';
          } else if (element.label == "DASHBOARD.BUTTON.REQUESTS") {
            element.label = this.validTitleTraductions(33);
            element.type = 'array';
          } else {
            element.type = 'string';
          }
        });

      }
      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    // should never get here, but just in case
    return breadcrumbs;
  }

  validTitleTraductions(id: number) {
    return this.data.find(t => t.id === id) != null && this.data.find(t => t.id === id) != undefined ? this.data.find(t => t.id === id).translations : '';
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, finalize, first } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentMethodModel } from '../../models/payment-method.model';
import { AuthService } from '@fi-sas/webpage/auth/services/auth.service';
import { ChargeAccountModel } from '../../models/charge-account.model';
import { MovementsService } from '../../services/movements.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CountryInterface, countries } from '@fi-sas/webpage/shared/data/countries_array';
import { PayMovementModel } from '../../models/pay-movement.model';
import * as moment from 'moment';
import * as  humanizeDuration from "humanize-duration";

@Component({
  selector: 'app-payment-component',
  templateUrl: './payment-component.component.html',
  styleUrls: ['./payment-component.component.less']
})
export class PaymentComponentComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  showBankLogos = environment.showBankLogosOnChargePage;

  @Input() available_payment_methods: PaymentMethodModel[] = [];
  @Input() mode: 'CHARGE' | 'PAY' = 'CHARGE';
  @Input() account_id: number;
  @Input() valueToPay: number;
  @Input() currentBalance: number;
  @Input() movementId: string;
  @Output() onPaymentSucess = new EventEmitter();

  selectedMethod
  valueCharge
  mobileNumber
  // VALUE AFTER TRANSACTION
  totalValue = 0;

  mobileIndicator: CountryInterface = countries.find(c => c.sigla2 === 'PT');
  contries: CountryInterface[] = countries.filter(c => c.dial_code);

  loadingPayment = false;

  dataPayment

  MBWayInterval = (5 * 60 * 1000);
  modalMBWay = false;
  modalMbWayUntil = null;
  modalMbWayRemaing = null;
  modalMBWayStatus = 'PENDING';
  modalMBWayInterval = null;
  modalMbWayLoadingStatus = false;
  modalMbWayPaymentId = null;
  modalMbWayPercentage = 0;


  modalATM

  successModal = false;

  constructor(
    private authService: AuthService,
    private movementsService: MovementsService,
    private translateService: TranslateService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  validPaymentMethods(paymentMethods: PaymentMethodModel[]) {
    if (this.mode == 'CHARGE') {
      return paymentMethods.filter(pm => !['CC', 'NUM', 'BKTRF', 'MB', 'MB_KIOSK', 'EXEC_FISC'].includes(pm.tag))
    } else {
      return paymentMethods.filter(pm => pm.allow_payment).filter(pm => !['NUM', 'BKTRF', 'MB', 'MB_KIOSK', 'EXEC_FISC'].includes(pm.tag))
    }
  }

  noPaymehtMethods(paymentMethods: PaymentMethodModel[]) {
    return this.validPaymentMethods(paymentMethods).length === 0;
  }

  selectPaymentMethod(paymentMethod: PaymentMethodModel) {
    this.selectedMethod = paymentMethod;
    this.valueCharge = 0;
    this.changeValue();
    this.mobileNumber = this.authService.getUser()?.phone || '';
  }

  changeValue() {
    this.totalValue = this.currentBalance + ((-this.valueToPay) || this.valueCharge);
  }

  getIconByTag(paymentMethod: PaymentMethodModel) {
    if (paymentMethod.tag.toLocaleLowerCase().includes('paypall')) {
      return 'paypall';
    }

    if (paymentMethod.tag.toLocaleLowerCase().includes('refmb')) {
      return 'multibanco';
    }


    //MB

    //MB_KIOSK

    //NUM

    if (paymentMethod.tag.toLocaleLowerCase().includes('mbway')) {
      return 'MBWAY';
    }

    if (paymentMethod.tag.toLocaleLowerCase().includes('card')) {
      return 'CARD';
    }

    //CC
    if (paymentMethod.tag.toLocaleLowerCase().includes('cc')) {
      return 'CURRENT_ACCOUNT';
    }

    return 'default'
  }

  isCC() {
    return this.selectedMethod.tag.toLocaleLowerCase().includes('cc');
  }

  isPaypal() {
    return this.selectedMethod.tag.toLocaleLowerCase().includes('paypall');
  }

  isRefMb() {
    return this.selectedMethod.tag.toLocaleLowerCase().includes('refmb');
  }

  isMbWay() {
    return this.selectedMethod.tag.toLocaleLowerCase().includes('mbway');
  }

  isCard() {
    return this.selectedMethod.tag.toLocaleLowerCase().includes('card');
  }

  sanitizeMobile(): string {
    return this.mobileIndicator.dial_code.replace('+', '') + '#' + this.mobileNumber;
  }

  disabledConfirmButton(): boolean {
    if (this.mode == 'CHARGE') {

      if(this.valueCharge == 0) {
        return true;
      }

      if(this.selectedMethod.min_charge_amount && this.valueCharge < this.selectedMethod.min_charge_amount) {
        return true;
      }
      if(this.selectedMethod.max_charge_amount && this.valueCharge > this.selectedMethod.max_charge_amount) {
        return true;
      }

    }
    if (this.mode == 'PAY' && this.valueToPay == 0) {
      return true;
    }

    if (this.isMbWay()) {
      if ((!this.mobileNumber || !this.mobileIndicator))
        return true;

      if (!(new RegExp(/^\d{1,5}#\d{4,11}$/).test(this.sanitizeMobile())))
        return true;

    }

    return false;
  }

  confirm() {
    this.loadingPayment = true;

    if (this.mode == 'CHARGE') {
      let sendValue: ChargeAccountModel = new ChargeAccountModel();
      sendValue.account_id = this.account_id;
      sendValue.payment_method_id = this.selectedMethod.id;
      sendValue.transaction_value = this.valueCharge;

      if (this.isMbWay()) {
        sendValue.customer_phone = this.sanitizeMobile();
      } else {
        sendValue.customer_phone = null;
      }


      this.movementsService.chargeAccount(sendValue).pipe(first(), finalize(() => this.loadingPayment = false)).subscribe((res) => {
        if (this.isRefMb()) {
          this.modalATM = true;
          this.dataPayment = res.data[0].payment;
        }
        if (this.isMbWay()) {
          this.startMBWayModal(res.data[0].payment.id);
        }
        if (this.isCard()) {
          this.router.navigate(["current-account", "card-payment"], {
            queryParams: { paymentId: res.data[0].payment.id },
            state: {
              payment: res.data[0].payment
            }
          })
        }
      })

    } else if (this.mode == 'PAY') {
      let sendValue: PayMovementModel = new PayMovementModel();

      if (this.isMbWay()) {
        sendValue.customer_phone = this.sanitizeMobile();
      } else {
        sendValue.customer_phone = null;
      }

      sendValue.payment_method_id = this.selectedMethod.id;
      sendValue.id = this.movementId;
      this.movementsService.payMovement(sendValue).pipe(first(), finalize(() => this.loadingPayment = false)).subscribe((res) => {

        if(this.isCC()) {
          this.onPaymentSucess.emit(true);
        }

        if (this.isRefMb()) {
          this.modalATM = true;
          this.dataPayment = res.data[0];
        }
        if (this.isMbWay()) {
          this.startMBWayModal(res.data[0].id);
        }
        if (this.isCard()) {
          this.router.navigate(["current-account", "card-payment"], {
            queryParams: { paymentId: res.data[0].id },
            state: {
              payment: res.data[0]
            }
          })
        }
      });

    }

  }


  MBWayFormat = () => {
    switch (this.modalMBWayStatus) {
      case 'PENDING':
        return this.modalMbWayRemaing
      case 'FAILED':
        return 'Falha no pagamento!';
      case 'SUCCESS':
        return 'Pagamento com sucesso!';
      default:
        return this.modalMbWayRemaing
    }
  };

  startMBWayModal(paymentId) {

    this.modalMbWayUntil = moment().add(5, 'minutes');
    this.modalMbWayRemaing = humanizeDuration(this.MBWayInterval, { language: this.translateService.currentLang, round: true });
    this.modalMBWayStatus = 'PENDING';
    this.modalMBWayInterval = null;
    this.modalMbWayLoadingStatus = false;
    this.modalMbWayPaymentId = paymentId;
    this.modalMbWayPercentage = 0;

    this.modalMBWay = true;
    this.modalMBWayInterval = setInterval(() => {
      let diff = moment(this.modalMbWayUntil).diff(new Date());
      this.modalMbWayPercentage = (100 / this.MBWayInterval) * (diff);

      this.modalMbWayRemaing = humanizeDuration(diff, { language: this.translateService.currentLang, round: true });

      if (!this.modalMbWayLoadingStatus) {
        this.modalMbWayLoadingStatus = true;
        this.movementsService.getPayment(this.modalMbWayPaymentId).pipe(
          first(),
          finalize(() => this.modalMbWayLoadingStatus = false)
        ).subscribe(result => {
          if (result.data[0].status !== 'PENDING') {
            this.modalMbWayPercentage = 0;
            this.modalMBWayStatus = result.data[0].status == 'CONFIRMED' ? 'SUCCESS' : 'FAILED';
            clearInterval(this.modalMBWayInterval);
          }
        });
      }

      if (diff < 0) {
        this.modalMBWayStatus = 'FAILED';
        clearInterval(this.modalMBWayInterval);
      }
    }, 1000)
  }

  closeMBWayModal() {
    this.modalMBWay = false;
    clearInterval(this.modalMBWayInterval);

    this.movementsService.getBalances(true);
    this.selectedMethod = null;
  }

  confirmMBWayModal() {
    this.onPaymentSucess.emit(true);
    this.closeMBWayModal();
  }

  getProgressStatus() {
    switch (this.modalMBWayStatus) {
      case 'PENDING':
        return 'active';
      case 'FAILED':
        return 'exception';
      case 'SUCCESS':
        return 'success';
      default:
        return 'active';
    }
  }

  searchCountry(search) {
    if (!search) {
      this.contries = countries.filter(c => c.dial_code);
    }
    search = search.toLowerCase();
    this.contries = countries
      .filter(c => c.dial_code)
      .filter(c =>
        c.nome.toLowerCase().includes(search) ||
        c.dial_code.toLowerCase().includes(search) ||
        c.sigla2.toLowerCase().includes(search)
      );
  }


    
  closeATMModal() {
    this.modalATM = false;
    this.movementsService.getBalances();
    this.selectedMethod = null;

    this.onPaymentSucess.emit(false);
  }
}

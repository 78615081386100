<div class="notifications">
    <button nz-button nzType="link" nz-popover id="popover-notifications"
        [nzPopoverTitle]="'NOTIFICATIONS.TITLE' | translate" [(nzPopoverVisible)]="visibleNotifications"
        nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate" class="notification-button"
        [ngStyle]="{'margin-right': total ? '7px':'0px'}">
        <nz-badge [nzCount]="total" [nzOverflowCount]="99">
            <i nz-icon nzType="bell" style="font-size:19px" nzTheme="outline" class="header-icon"></i>
        </nz-badge>
    </button>
</div>
<ng-template #contentTemplate>
    <div *ngIf="!loading">
        <nz-list [nzDataSource]="$notifications | async" [nzRenderItem]="defaultItem" [nzItemLayout]="'horizontal'"
            style="font-size: 12px; height: 300px; overflow-y: scroll; width: 250px;">
            <ng-template #defaultItem let-notification>
                <div nz-row style="padding-top: 8px;">
                    <div nz-col [nzXs]="24" [nzMd]="4" [nzLg]="3">
                        <i class="icon" nz-icon [nzType]="changeIcon(notification?.service?.id)" id="icon-noti"></i>

                    </div>
                    <div nz-col [nzXs]="24" [nzMd]="17" [nzLg]="18" style="padding-top: 2px;">
                        <span class="text-title" [ngClass]="{'bold': notification.unread}"
                            (click)="selectNotification(notification);">{{notification.subject}}</span>
                        <br>
                        <span class="description">{{notification?.created_at | date: "dd-MM-yyyy"}} -
                            {{notification?.created_at |
                            date:
                            "HH:mm"}}h</span>
                        <div style="padding-bottom: 10px;">
                            <span class="text-description"
                                *ngIf="notificationSelect.id == notification.id">{{notification.message}}</span>
                        </div>
                    </div>
                    <div nz-col [nzXs]="24" [nzMd]="3" [nzLg]="2">
                        <i nz-icon nzType="close" nzTheme="outline" nz-tooltip
                            [nzTooltipTitle]="'MISC.DELETE' | translate" (click)="delete(notification.id)"
                            class="icon-delete"></i>
                    </div>
                    <nz-divider style="margin:0px!important;"></nz-divider>
                </div>
            </ng-template>
        </nz-list>
    </div>
    <div *ngIf="loading" nz-row class="padding-loading">
        <nz-spin [nzSpinning]="loading">

        </nz-spin>
    </div>
    <div nz-row class="border-buttons">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="11" [nzLg]="11" [nzXl]="11" [nzXXl]="11" class="button-more">
            <button nz-button class="button ant-btn ant-btn-default ant-btn-round" (click)="showMore()"
                [disabled]="!(($notificationsLength | async) != $notifications.source._value.length)">{{'REPORTS.SHOWMORE'
                |
                translate}}</button>
        </div>
        <div nz-col [nzXs]="0" [nzSm]="0" [nzMd]="2" [nzLg]="2" [nzXl]="2" [nzXXl]="2">

        </div>
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="11" [nzLg]="11" [nzXl]="11" [nzXXl]="11" class="space-button">
            <button nz-button class="ant-btn ant-btn-default ant-btn-round button"
                (click)="refresh();">{{'REPORTS.REFRESH' | translate}}</button>
        </div>
    </div>
</ng-template>
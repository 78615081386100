import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFromObjectsArray'
})
export class GetFromObjectsArrayPipe implements PipeTransform {
  transform(key: string, compareKey: string, list: {[key: string] : any}[], retrieveKey: string, defaultReturn: any = null): any {
    const item = list.find((obj) => obj[compareKey] === key);
    return item ? item[retrieveKey || compareKey] : defaultReturn;
  }
}

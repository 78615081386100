import { Injectable, Inject, InjectionToken, Optional } from '@angular/core';

import { isObject, mapKeys, set, startsWith, get } from 'lodash';
import { Subject } from 'rxjs';

export interface Options {
  [key: string]: any;
}

export const OPTIONS_TOKEN = new InjectionToken<Options>(
  '[CONFIG] Json config'
);
export const DEFAULT_CONFIG_OPTIONS: Options = {};

@Injectable()
export class FiConfigurator {
  listener = new Subject<Options>();

  private repository: Options = <Options>{};

  constructor(
    @Optional()
    @Inject(OPTIONS_TOKEN)
    options?: Options | undefined
  ) {
    this.options = options || {};
  }

  setOption(name: string, value: any): void {
    this.repository[name] = value;
    this.listener.next({ options: this.repository });
  }

  /**
   * Get a configuration value from the collection.
   *
   */
  getOption<O>(name: string, defaults: any = null): O {
    return this.hasOption(name) ? this.repository[name] : defaults;
  }

  getOptionTree<T>(rootKey: string, fromRoot: boolean = true): T {
    const tree = {};

    mapKeys(this.options, (value: any, key: string) => {
      if (startsWith(key, rootKey)) {
        set(tree, key, value);
      }

      return key;
    });

    return fromRoot ? tree : get(tree, rootKey);
  }

  get options(): Options {
    return this.repository;
  }

  set options(opt: Options) {
    this.flat(opt);
  }

  reset(): void {
    this.options = {};
    this.repository = {};
  }

  /**
   * Verify if option name exists on the collection.
   *
   */
  hasOption(name: string): boolean {
    return this.repository.hasOwnProperty(name);
  }

  /**
   * Converts a tree object keys in flat
   * key string in one level.
   *
   * {
   *  name: '',
   *  profile: {
   *    email: ''
   *  }
   * }
   *
   * to: {'name': '', 'profile.email': ''}
   *
   */
  private flat(config: any, key: string = ''): void {
    const path: string = +(key === '') ? key : key + '.';

    Object.keys(config).forEach((keyId: string) => {
      if (isObject(config[keyId])) {
        this.flat(config[keyId], path + keyId);
      } else {
        this.setOption(`${path + keyId}`, config[keyId]);
      }
    });
  }
}

import { Injectable, Inject } from '@angular/core';
import { find, head, isEmpty } from 'lodash';
import { GenericType, flatten } from '@fi-sas/utils';
import {
  UrlResolverValidationError,
  UrlResolverTestError
} from './url.exception';
import { FiConfigurator } from '@fi-sas/configurator';

export interface DomainHostType {
  HOST: string;
  KEY: string;
}

export interface RouteDescriptorType {
  name: string;
  prefix: string;
  uri: string;
  endpoint: string;
}

@Injectable()
export class FiUrlService {
  static OPTION_ENDPOINT_NAME = 'ENDPOINTS';
  static OPTIONS_DOMAIN_NAME = 'DOMAINS_API';

  prefixes: DomainHostType[] = [];
  routes: Map<string, RouteDescriptorType> = new Map();

  constructor(@Inject(FiConfigurator) private configurator: FiConfigurator) {
    this.routes = new Map();
    this.init();
  }

  /**
   * Init setup for resolving routes collection.
   *
   * @memberof UrlResolver
   */
  init(): void {
    const { keys } = Object;
    const endpoints = this.configurator.getOptionTree<GenericType>(
      FiUrlService.OPTION_ENDPOINT_NAME,
      false
    );

    this.prefixes = this.configurator.getOptionTree<DomainHostType[]>(
      FiUrlService.OPTIONS_DOMAIN_NAME,
      false
    );

    if (endpoints) {
      const flatEndpoints = flatten(endpoints);

      keys(flatEndpoints).forEach(key =>
        this.setupRoute(key, flatEndpoints[key])
      );
    }
  }

  /**
   * Setup regex routing and add it to Map.
   *
   * @param {string} name
   * @param {string} uri
   * @memberof UrlResolver
   */
  setupRoute(name: string, uri: string) {
    const verbal = new RegExp(/^@\w+\:/);
    const search = head(verbal.exec(uri)) || '';

    const urlDescriptor = {
      name: name,
      prefix: search,
      uri: uri,
      endpoint: uri.replace(search, '')
    } as RouteDescriptorType;

    this.addRoute(name, urlDescriptor);
  }

  /**
   * Add route to Map.
   *
   * @param {string} name
   * @param {RouteDescriptorType} descriptor
   * @memberof UrlResolver
   */
  addRoute(name: string, descriptor: RouteDescriptorType) {
    this.routes.set(name, descriptor);
  }

  /**
   * Get the route from Map and resolve domain host. Pass parameters
   * to complete dynamic arguments on route, even overriding domain host is possible.
   *
   * @param {string} name
   * @param {GenericType} [params=null]
   * @param {string} [domain=null]
   * @returns
   * @memberof UrlResolver
   */
  get(name: string, params: GenericType | null = null, domain: string | null = null) {
    /*if (name.includes('.')) {
      name = name.substr(name.indexOf('.') + 1);
    }*/

    const routeDescriptor: RouteDescriptorType | undefined = this.routes.get(name);

    if (isEmpty(routeDescriptor)) {
      throw new UrlResolverValidationError(
        name,
        'Uri is not setup on mappings.'
      );
    } else {
      if (routeDescriptor) {
        const resolved = this.resolve(routeDescriptor, params ? params : {}, domain ? domain : '');

        return resolved.url;
      }

      throw new UrlResolverValidationError(
        name,
        'routeDescriptor is undefined.'
      );
    }
  }

  /**
   * Resolve host domain and route.
   *
   * @param {RouteDescriptorType} descriptor
   * @param {GenericType} args
   * @param {string} [host='']
   * @returns
   * @memberof UrlResolver
   */
  resolve(descriptor: RouteDescriptorType, args: GenericType, host = '') {
    const regex = this.expression(descriptor.endpoint);
    const test = regex.test(descriptor.endpoint);
    const prefix = descriptor.prefix.substring(0, descriptor.prefix.length - 1);

    if (test) {
      let url: string | null = null;
      const parameters = [];

      const regexResult = regex.exec(descriptor.endpoint);
      if (regexResult) {
        regexResult.slice(1)
          .forEach(arg => {
            if (arg) {
              parameters.push(decodeURIComponent(arg));
            }

            if (args && arg) {
              url = url
                ? url.replace(arg, args[arg.substring(1, arg.length)])
                : descriptor.endpoint.replace(
                  arg,
                  args[arg.substring(1, arg.length)]
                );
            }
          });

        const domain = find<DomainHostType>(this.prefixes, ['KEY', prefix]);
        const domainHost = domain && isEmpty(host) ? domain.HOST || '' : host;

        return {
          name: descriptor.name,
          host: domainHost,
          url: url
            ? `${domainHost}${url}`
            : `${domainHost}${descriptor.endpoint}`,
          params: args,
          regex: regex.source
        };
      }
    }
    throw new UrlResolverTestError(
      descriptor.name,
      'UrlResolver test didnt match any url.'
    );

  }

  /**
   * Macth route arguments.
   *
   * @param {string} route
   * @returns
   * @memberof UrlResolver
   */
  expression(route: string) {
    const splatParam = /\*\w+/g;
    const namedParam = /(\(\?)?:\w+/g;
    const optionalParam = /\((.*?)\)/g;
    const escapeRegExp = /[\-{}\[\]+?.,\\\^$|#\s]/g;

    route = route
      .replace(escapeRegExp, '\\$&')
      .replace(optionalParam, '(?:$1)?')
      .replace(namedParam, (match, optional) => {
        return optional ? match : '([^/?]+)';
      })
      .replace(splatParam, '([^?]*?)');

    return new RegExp('^' + route + '(?:\\?([\\s\\S]*))?$');
  }
}

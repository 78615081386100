import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FiResourceService, FiUrlService } from '@fi-sas/core';
import { UserModel } from '@fi-sas/webpage/auth/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private resourceService: FiResourceService, private urlService: FiUrlService) {}

  searchUsers(search?: string, searchFields: string[] = []) {
    let params = new HttpParams();
    params = params.set('offset', '0');
    params = params.set('limit', '20');
    if (search) {
      params = params.set('search', search);
      if (!(searchFields || []).length) {
        searchFields = ['name', 'student_number', 'email', 'identification', 'tin'];
      }
      params = params.set('searchFields', searchFields.join(','));
    }
    return this.resourceService.list<UserModel>(this.urlService.get('USERS.USERS'), { params });
  }

  searchUserByEmail(email: string) {
    return this.searchUsers(email, ['email']);
  }

  getUser(id: number) {
    return this.resourceService.read<UserModel>(this.urlService.get('USERS.USERS_ID', { id }));
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  isDevMode,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  Router,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  NavigationStart,
  Event,
} from "@angular/router";

import { CookieService } from "ngx-cookie";
//import { en_US, NzI18nService, pt_PT } from "ng-zorro-antd";
import { NzNotificationComponent, NzNotificationService } from "ng-zorro-antd/notification";
import { en_US, NzI18nService, pt_PT } from "ng-zorro-antd/i18n";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

import { AuthService } from "./auth/services/auth.service";
import { environment } from "src/environments/environment";
import { VersionCheckService } from "./core/services/version-check.service";
import { SeoService } from "./core/services";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = "fisas-webpage";
  routerLoading = false;

  @ViewChild("cookieMsg") cookieMsg: TemplateRef<{}> | undefined;

  onLangChange: Subscription | undefined = undefined;

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private i18n: NzI18nService,
    private notification: NzNotificationService,
    private router: Router,
    private seoService: SeoService,
    public el: ElementRef,
    public translate: TranslateService,
    public versionCheckService: VersionCheckService
  ) {
    router.initialNavigation();

    // SETUP TRANSLATIONS
    translate.addLangs(["en", "pt"]);
    translate.setDefaultLang("pt");

    const browserLang = translate.getBrowserLang();
    const langStorage = localStorage.getItem("language");
    this.seoService.update();
    translate.use(
      langStorage ? langStorage : (browserLang ? (browserLang.match(/pt|en/) ? browserLang : "pt") : "pt")
    );
    translate.onLangChange.subscribe((data) => {
      localStorage.setItem("language", data.lang);
      if (data.lang == "pt") {
        this.i18n.setLocale(pt_PT);
      } else {
        this.i18n.setLocale(en_US);
      }
      this.seoService.update();
    });


    // CHECK VERSION
    versionCheckService.initVersionCheck(environment.version_check_url);

    // CHECK IF IS LOGIN FROM SSO
    if (environment.hasSSO && !this.authService.isGuest) {
      const authCookie = this.cookieService.get("authToken");
      const authFirstLogin = this.cookieService.get("authFirstLogin");

      if (authFirstLogin === "true") {
        this.router.navigate(["firstLogin"]);
      }
    }

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          setTimeout(() => {
            this.routerLoading = true;
          }, 50);
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          setTimeout(() => {
            this.routerLoading = false;
          }, 50);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    this.updateLanguage();
    this.onLangChange = this.translate.onLangChange.subscribe(() => {
      this.updateLanguage();
    });

  }


  ngAfterViewInit(): void {
    if (!this.cookieService.get("cookieAcceptanceWP") && this.cookieMsg) {
      this.notification.template(this.cookieMsg, {
        nzDuration: 0,
      });
    }
  }

  ngOnDestroy() {
    if (this.onLangChange !== undefined) {
      this.onLangChange.unsubscribe();
    }
  }


  cookieConsent(notification: NzNotificationComponent) {
    notification.close();
    this.cookieService.put("cookieAcceptanceWP", "true", {
      expires: moment().add(1, "year").toDate(),
    });
  }


  /**
   * Update the language in the lang attribute of the html element.
   */
  updateLanguage(): void {
    const lang = document.createAttribute("lang");
    lang.value = this.translate.currentLang;
    this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(
      lang
    );
  }

  scrollToMainElement(button): void {
    const mainElements = document.getElementsByTagName("main");
    if(mainElements.length > 0) {
      mainElements[0].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      mainElements[0].focus();  
      button.blur();
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { RgpdService } from '../../rgpd/rgpd.service';
import { finalize, first } from 'rxjs';
import { RgpdModel, TranslationsModel } from '../../models/consentRgpd.model';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModel } from '@fi-sas/webpage/modules/private-accommodation/models/language.model';
import { FiConfigurator } from '@fi-sas/webpage/libs/configurator';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-rgpd',
  templateUrl: './rgpd.component.html',
  styleUrls: ['./rgpd.component.less']
})
export class RgpdComponent implements OnInit {

  id: number;
  currentRgpd: TranslationsModel
  languages: any

  loadingRGPD: boolean = false

  currentLang = {};

  constructor(
    private rgpdService: RgpdService,
    private translate: TranslateService,
    private config: FiConfigurator,
    private sanitizer: DomSanitizer,
  ) {
    this.languages = Object.values(config.getOptionTree('LANGUAGES'))[0];
  }

  ngOnInit() {
    this.getRgpdById();
  }

  getRgpdById() {
    this.loadingRGPD = true
    this.rgpdService.getRgpd(this.id)
      .pipe(first(), finalize(() => this.loadingRGPD = false))
      .subscribe((res) => {
        const currentLang = this.languages.find(lang => lang.acronym === this.translate.currentLang)
        this.currentRgpd = res.data[0].translations.filter(t => t.language_id === currentLang.id)[0]
      });
  }

  getHTML(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }




}

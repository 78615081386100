import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MessageType, UiService } from '@fi-sas/webpage/core/services/ui.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FiConfigurator } from '@fi-sas/configurator';
import { environment } from '../../../environments/environment';

@Injectable()
export class WebpageErrorHandle implements ErrorHandler {

  constructor(private injector: Injector) {}

  public handleError(error: Error | HttpErrorResponse) {

    if (!environment.production) {
      console.error(error);
    }

    if (!(error instanceof HttpErrorResponse)) {
      this.showError(error.message);
    }

  }

  private showError(message: string) {
    const config = this.injector.get(FiConfigurator);
    const messageProd = config.getOption<string>('ERROR_TRANSLATOR.REFRESH_PAGE', 'Ocorreu um erro, por favor actualize a página');

    const uiService = this.injector.get(UiService);
    uiService.showMessage(MessageType.error, environment.production ? messageProd : message, {
    });
  }
}

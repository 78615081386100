import { Injectable, OnDestroy } from '@angular/core';
import { GenericType } from '@fi-sas/utils';

@Injectable()
export class FiPriorityService implements OnDestroy {
  public data: { value: GenericType, probability: number }[] = [];
  public universe = 0;

  /**
   * Add a item to the priorities universe.
   *
   * @param {GenericType} data
   * @param {number} probability
   * @memberof FiPriorityService
   */
  public add(data: GenericType, probability: number) {
    this.data.push({
      value: data,
      probability: Math.abs(probability)
    });

    this.universe += this.data[this.data.length - 1].probability;
  }

  /**
   * Get a item from the priorities universe.
   *
   * @returns
   * @memberof FiPriorityService
   */
  public get() {
    if (!this.universe) {
      return null;
    }

    const x = Math.round(this.randomIntFromInterval(1, this.universe));

    let max = 0;
    let i = 0;

    while (x > max) {
      max += this.data[i++].probability;
    }

    const val =
      this.universe === 1 ? this.data[i].value : this.data[i - 1].value;

    return val;
  }

  /**
   * Get a random integer from interval.
   *
   * @private
   * @param {number} min
   * @param {number} max
   * @returns
   * @memberof FiPriorityService
   */
  private randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /**
   * On destroy.
   */
  ngOnDestroy() {
    this.data = [];
    this.universe = 0;
  }
}

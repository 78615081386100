import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FiUrlService } from '@fi-sas/webpage/libs/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentDataModel } from '../../models/movements.model';
import { MovementsService } from '../../services/movements.service';

@Component({
  templateUrl: './card-payment.component.html',
  styleUrls: ['./card-payment.component.less']
})
export class CardPaymentComponent implements OnInit, AfterViewInit {
  
  paymentId: string;

  sibsFormConfig = null;

  sibsFormStyle =  { 
    "transaction": {
      "layout": 'default',
      "theme": 'default',
      "color": {
        "primary": "",
        "secondary": "",
        "border": "",
        "surface": "",  
        "header": {
          "text": "",  
          "background": ""
        },
        "body": {  
          "text": "",  
          "background": ""
        }
      },
    },
    "font": "Montserrat"
  };  
  
  sibsFormContext = null;

  payment: PaymentDataModel = null;

  loadAPI: Promise<any>;

  constructor(
    activeRoute: ActivatedRoute,
    router: Router,
    private translateService: TranslateService,
    private movementsService: MovementsService,
    private urlService: FiUrlService, 
  ) {
    this.payment = router.getCurrentNavigation().extras['payment'];
    this.paymentId = activeRoute.snapshot.queryParams['paymentId'];
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.loadPayment();
  }

  loadPayment() {
    this.sibsFormContext = "";
    if(!this.payment) {
      this.movementsService.getPayment(this.paymentId).pipe(first()).subscribe(result => {
          this.payment = result.data[0];
          this.sibsFormContext = this.payment.payment_method_data['formContext'];

          if(this.payment.status == 'PENDING') {
            this.loadForm();
          }
      });
    } else {
      this.sibsFormContext = this.payment.payment_method_data['formContext'];

      if(this.payment.status == 'PENDING') {
        this.loadForm();
      }
        
    }
  }

  loadForm() {

    this.sibsFormConfig = { 
      "paymentMethodList": [],
      "amount": { "value": this.payment.value, "currency": "EUR" },
      "language": this.translateService.currentLang,
      "redirectUrl": this.urlService.get('SIBS.CARD_REDIRECT', { paymentId: this.paymentId}),
      "customerData": null 
    }

    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }

  public loadScript() {        
      var isFound = false;
      var scripts = document.getElementsByTagName("script")
      for (var i = 0; i < scripts.length; ++i) {
          if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
              isFound = true;
          }
      }
  
      if (!isFound) {
          var dynamicScripts = [`https://spg.qly.site1.sibs.pt/assets/js/widget.js?id=${this.payment.payment_method_data['transactionID']}`];
  
          for (var i = 0; i < dynamicScripts.length; i++) {
              let node = document.createElement('script');
              node.src = dynamicScripts [i];
              node.type = 'text/javascript';
              node.async = false;
              node.charset = 'utf-8';
              document.getElementsByTagName('head')[0].appendChild(node);
          }
  
      }
  }
}

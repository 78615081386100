import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {


  constructor(private translateService: TranslateService) { }

  transform(value: any): any {
    return formatDate(
      value,
      'd MMMM y',
      this.translateService.currentLang
    );
  }
}

<div class="footer" nz-row nzType="flex">
  <!--Informations links-->
  <div class="footer__informations" nz-col [nzXs]="24" [nzLg]="8">
    <span>
      <a [routerLink]="['/regulations', 'informations']" target="_blank">
        {{ "FOOTER.INFORMATIONS" | translate }}
      </a>
      <a [routerLink]="['/regulations', 'terms']" target="_blank">
        {{ "FOOTER.TERMS" | translate }}
      </a>
      <a [routerLink]="['/regulations', 'privacy_politics']" target="_blank">
        {{ "FOOTER.PRIVACY_POLICIES" | translate }}
      </a>
      <a [href]="faultComunicationLink" target="_blank">
        {{"FOOTER.FAULT_COMMUNICATION" | translate}}
      </a>
      <a [routerLink]="['/acessibilidade']" target="_blank">
        {{"FOOTER.ACCESSIBILITY_DECLARATION" | translate}}
      </a>
      
    </span>
  </div>

  <!--Copyright-->
  <div class="footer__copyright" nz-col [nzXs]="0" [nzLg]="8">
    <span>sasocial © {{ current_date | date: "yyyy" }} | v{{ version }}</span>
  </div>

  <div class="footer__logo" nz-col [nzXs]="0" [nzLg]="8">
    <!--Logo Ama-->
    <img *ngIf="logo_type == FooterLogoType.AMA" alt="Agência para a Modernização Administrativa, I.P. (AMA)" src="/assets/imgs/compete.svg" />
    <!--Logo Prr-->
    <img *ngIf="logo_type == FooterLogoType.PRR" alt="Plano de Recuperação e Resiliência (PRR)" src="/assets/imgs/prr.svg" />
  </div>
</div>

import { AuthService, AuthObject } from '@fi-sas/webpage/auth/services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { filter, first, switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let auth = this.authService.getAuth();
    if (auth && auth.token && !request.headers.has('no-token')) {

      if (moment(auth.expires_in).subtract(5, 'minutes').isBefore() && !this.authService.isRefreshing) {
        return this.authService.refreshToken().pipe(first(), switchMap(() => {
          auth = this.authService.getAuth();
          const cloned = request.clone({
            headers: request.headers.append('Authorization', `Bearer ${auth.token}`)
          });
          return next.handle(cloned);
        }));
      } else {
        return this.authService.$isRefreshing.pipe(
          filter((v) => v === false), first(), switchMap(() => {
            auth = this.authService.getAuth();
            const cloned = request.clone({
              headers: request.headers.append('Authorization', `Bearer ${auth.token}`)
            });

            return next.handle(cloned);
          }));

      }

    } else {
      return next.handle(request);
    }
  }
}

import { FiConfigurator } from '@fi-sas/configurator';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private configService: FiConfigurator, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.guestOrUserVerification(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.guestOrUserVerification(route, state);
  }

  guestOrUserVerification(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return Observable.create(observable => {

      if (!this.authService.getIsLogged() && !this.authService.getIsGuest()) {
        observable.next(false);
      } else {
        if (this.authService.getIsLogged() || this.authService.getIsGuest()) {
          observable.next(true);
        } else {
          // IF TOKEN IS NOT VALID
          observable.next(false);
        }
      }
      observable.complete();
    });
  }
}

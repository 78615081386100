import { Component, OnInit } from "@angular/core";
import { first } from "rxjs/operators";
import { ReportModel } from "../../models/reports.model";
import { ReportsService } from "../../services/reports.service";

@Component({
    selector: 'app-layout-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.less']
})

export class LayoutReportsComponent implements OnInit {

    visibleReports: boolean;
    $reports = null;
    $reportsLength = null;
    loading = true;
    total: number = 0;

    constructor(private reportsService: ReportsService) {
        this.getReports();
        this.getReportsLength();
        this.reportsService.total$.subscribe(
            (total) => {
                this.total = total;
            }
        );
    }

    ngOnInit() {

    }

    getReports() {
        this.$reports = null;
        this.$reports = this.reportsService
            .getReportsObservable();
        this.loading = false;
    }

    getReportsLength() {
        this.$reportsLength = null;
        this.$reportsLength = this.reportsService
            .getReportsLengthObservable();
    }

    refresh() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
            this.reportsService.loadData();
        }, 200);
    }

    clickMe(): void {
        this.visibleReports = false;
    }

    change(value: boolean): void {
    }

    showMore() {
        this.reportsService.loadData(true);
    }

    download(report: ReportModel) {
        if(report.unread == true) {
            this.reportsService.updateReadReport(report.id).pipe(first()).subscribe(() => {
                report.unread = false;
                this.reportsService.decrementTotal();
            });
        }
        window.open(report.file.url);
      
    }

    delete(id) {
        this.reportsService.delete(id).pipe(first()).subscribe((data)=> {
            this.refresh();
        })
    }

}
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UiService } from '@fi-sas/webpage/core/services/ui.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.less']
})
export class LayoutHeaderComponent implements OnInit, AfterContentChecked {

  institute = environment.institute;

  constructor(private uiService: UiService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public translate: TranslateService) { }

  ngOnInit() {
  }

  ngAfterContentChecked() {
    //if (this.cdr)
    //this.cdr.detectChanges();
  }

  openSideMenu(): void {
    this.uiService.triggerDrawerSider();
  }

  goDashboard() {
    this.router.navigate(['/dashboard']);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardPaymentRoutingModule } from './card-payment-routing.module';
import { CardPaymentComponent } from './card-payment.component';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [
    CardPaymentComponent
  ],
  imports: [
    CommonModule,
    NzResultModule,
    NzSpinModule,
    NzButtonModule,
    CardPaymentRoutingModule
  ]
})
export class CardPaymentModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CardPaymentComponent } from './card-payment.component';

const routes: Routes = [
  {
    path: '',
    component: CardPaymentComponent,
    data: { breadcrumb: null, title: null, scope: 'current_account:accounts:read' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CardPaymentRoutingModule { }

<div class="error-model___content">
  <div nz-row nzType="flex" nzJustify="center">
    <div nz-col nzSpan="24">
      <p class="error-model___title">{{ messageTitle | translate }}</p>
    </div>
  </div>
  <div nz-row nzType="flex" nzJustify="center" class="error-model___icon">
    <div nz-col nzSpan="24" style="text-align: center;">
      <img alt="negative" src="/assets/imgs/cards-ilustration-negative.svg" />
    </div>
  </div>
  <div nz-row nzType="flex" nzAlign="middle" class="error-model___msg">
    <div nz-col nzSpan="24">
      <p>{{ message | translate }}</p>
    </div>
  </div>
  <div nz-row class="error-model___button" nzType="flex" nzJustify="center">
    <div nz-col nzSpan="24" style="text-align: center;">
      <button nz-button nzType="link" (click)="handleCancel()">{{ "SHARED.WEBPAGE_ERROR.CLOSE" | translate }}</button>
    </div>
  </div>
</div>
<div class="subheader">
  <nz-breadcrumb class="breadcrumb">
    <nz-breadcrumb-item></nz-breadcrumb-item>
    <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs; last as isLast">
      <a [ngClass]="{
          breadcrumb__link__last: isLast,
          breadcrumb__link: !isLast
        }" [routerLink]="[breadcrumb.url]">
        {{breadcrumb.label?.length<=2 ? ((breadcrumb?.label | languageChange)?.name) : (breadcrumb.label | translate) }}
          </a>
          <!-- FALTA OS PARAMETROS NOS BREAD CRUMB -->
    </nz-breadcrumb-item>
  </nz-breadcrumb>
</div>
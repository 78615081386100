import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoConnectionComponent } from './no-connection.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';



@NgModule({
  declarations: [
    NoConnectionComponent,
  ],
  imports: [
    CommonModule,
    NzSpinModule,
    NzIconModule.forChild([]),
  ],
  exports: [
    NoConnectionComponent,
  ],
  entryComponents: [
    NoConnectionComponent,
  ],
})
export class NoConnectionModule { }

import { Injectable } from '@angular/core';
import { FiResourceService, FiUrlService, Resource } from '@fi-sas/webpage/libs/core';
import { ConsentRGPDModel, RgpdModel } from '../models/consentRgpd.model';
import { Observable, first, BehaviorSubject, finalize, filter, takeUntil, catchError, tap } from 'rxjs';
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RgpdService {

  statusLoaded = new BehaviorSubject<boolean>(false);

  userStatus:ConsentRGPDModel[] = [];

  constructor(
    private resourceService: FiResourceService,
    private urlService: FiUrlService,
    private location: Location
  ) { }

  isLoaded(): boolean {
    return this.statusLoaded.value;
  }

  getUserStatus() {
    // GUARDAR  num array aqui no servido /status
    this.statusLoaded.next(false);
    return this.resourceService.list<ConsentRGPDModel>(
      this.urlService.get('CONFIGURATION.CONSENT_RGPD_STATUS'),{
        headers: {
          "no-error": "true",
        }
      }
    ).pipe(
      first(),
      finalize(() => this.statusLoaded.next(true))
    )
    .subscribe((res) => {
      this.userStatus = res.data;
    }, (err) => {
      this.userStatus = [];
    })
  }

  isServiceConsented(serviceId: number): Observable<ConsentRGPDModel> {
    return new Observable((subs) => {
      if(this.statusLoaded.value == false) {
        this.statusLoaded.pipe(
          filter(v => v),
          first(),
        ).subscribe(() => {
          const status = this.userStatus.find((s) => s.service_id === serviceId)
          subs.next(status);
          subs.complete();
        })
      } else {
        const status = this.userStatus.find((s) => s.service_id === serviceId)
        subs.next(status);
        subs.complete();
      }
    });
  }

  getRgpdId(serviceId) {
    const service = this.userStatus.find(us => us.service_id == serviceId);
    return service ? service.rgpd_id : null
  }

  getRgpd(rgpd_id: number): Observable<Resource<RgpdModel>>{
    const params = new HttpParams()
    .append('fields', 'id,version')
    .append('withRelated', 'translations');
    return this.resourceService.read<RgpdModel>(
      this.urlService.get('CONFIGURATION.CURRENT_RGPD', {id: rgpd_id}), {
        params,
        headers: {
          "no-error": "true",
        }
      }
    )
  }

  consent(rgpd_id) {
    return this.resourceService.create<ConsentRGPDModel>(
      this.urlService.get("CONFIGURATION.CONSENT"),
      {
        rgpd_id: rgpd_id
      }, {
        headers: {
          "no-error": "true",
        }
      }
    ).pipe(tap(() => this.getUserStatus()));
  }

}

import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-no-connection',
  templateUrl: './no-connection.component.html',
  styleUrls: ['./no-connection.component.less']
})
export class NoConnectionComponent implements OnInit {
  working = false;
  constructor(private modal: NzModalRef) { }

  ngOnInit() {
  }

  verifyConnection() {
    this.working = true;
    setTimeout(() => {
      if (navigator.onLine) {
        this.modal.destroy();
      }
      this.working = false;
    }, 1000);

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemErrorComponent } from './system-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';



@NgModule({
  declarations: [
    SystemErrorComponent
  ],
  imports: [
    CommonModule,
    NzGridModule,
    NzButtonModule,
    NzIconModule.forChild([]),
    TranslateModule.forChild(),
  ],
  exports: [
    SystemErrorComponent
  ],
  entryComponents: [
    SystemErrorComponent
  ]
})
export class SystemErrorModule { }

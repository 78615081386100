import { NzModalService } from 'ng-zorro-antd/modal';
import { finalize, first, tap, timeout } from "rxjs/operators";
import { MessageType } from "./../../core/services/ui.service";

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  CanActivateChild,
  Router,
} from "@angular/router";
import { Observable, observable } from "rxjs";
import { UiService } from "@fi-sas/webpage/core/services/ui.service";
import { of } from "rxjs";
import { ConfigurationsService } from "@fi-sas/webpage/shared/services";
import { RgpdService } from "./rgpd.service";
import { AuthService } from '@fi-sas/webpage/auth/services/auth.service';
import { stat } from 'fs';
import { RgpdComponent } from '../components/rgpd/rgpd.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: "root",
})
export class RgpdGuard implements CanActivate {
  objKey: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private rgpdService: RgpdService,
    private modalService: NzModalService,
    private translateService: TranslateService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {


    if(this.authService.isGuest) {
      return of(true);
    }

    const service_id = route.data['service_id'] || null;

    if(service_id) {
      return new Observable(subs => {
      this.rgpdService.isServiceConsented(service_id)
      .pipe(
        first()
      )
      .subscribe(status => {
        if(!status || status.consented_at) {
          subs.next(true);
          subs.complete();
        } else {
          const modalRef = this.modalService.create({
            nzMaskClosable: false,
            nzClosable: false,
            nzKeyboard: false,
            nzWidth: 750,
            nzBodyStyle: { 'max-height': '60vh', 'overflow-y': 'auto' },
            nzTitle: this.translateService.instant(
              "CORE.RGPD_DESCRIPTION"
            ),
            nzContent: RgpdComponent,
            nzComponentParams: {
              id: status.rgpd_id
            },
            nzOnOk: () => {
              modalRef.updateConfig({
                nzOkLoading: true,
              });
              this.rgpdService.consent(status.rgpd_id).pipe(first(), finalize(() => {
                modalRef.updateConfig({
                  nzOkLoading: false,
                });
              })).subscribe(() => {
                subs.next(true);
                subs.complete();
              })

            },
            nzOnCancel: () => {

              if(service_id == 22) { // GERAL SERVICE
                this.authService.logout().pipe(first()).subscribe((data) => {
                  this.router.navigate(['auth', 'login']);
                });
              }

              subs.next(false);
              subs.complete();
            },
          })
        }
      })




      })
    } else {
      return of(true);
    }
  }

}

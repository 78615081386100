import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-system-error',
  templateUrl: './system-error.component.html',
  styleUrls: ['./system-error.component.less']
})
export class SystemErrorComponent implements OnInit {
  messageTitle!: string;
  message!: string;

  constructor(private modal: NzModalRef) { }

  ngOnInit() {
  }

  handleCancel(): void {
    this.modal.destroy();
  }

}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FiUrlService, FiResourceService, Resource } from '@fi-sas/core';
import { AuthService } from '@fi-sas/webpage/auth/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ReportModel } from '../models/reports.model';


@Injectable({
  providedIn: 'root',
})
export class ReportsService {
    total$ = new BehaviorSubject<number>(0);
    reports: ReportModel[] = [];
    reportsData = new BehaviorSubject([]);
    reportsLength = new BehaviorSubject(0);
    page = 1;
    pageSize = 5;
    length = 0;
    constructor(private resourceService: FiResourceService,
        private urlService: FiUrlService, private authService: AuthService) { 
          this.authService.getIsLoggedObservable().subscribe((isLogged)=> {
            if(!isLogged) {
              this.reports = [];
              this.length = 0;
              this.reportsData.next(this.reports);
              this.reportsLength.next(this.length);
            }else{
               this.loadData();
            }
          })
        
        }
        
  list(): Observable<Resource<ReportModel>> {
    let params = new HttpParams();
    params = params.set('offset', ((this.page - 1) * this.pageSize).toString());
    params = params.set('limit', this.pageSize.toString());
    params = params.set('sort', '-created_at');
    return this.resourceService.list<ReportModel>(this.urlService.get('REPORTS.REPORTS', {}), {
      params
    });
  }

  loadData(increment?: boolean){
    increment ? this.page++ : this.page = 1;
    let total = 0;
    this.list().pipe(
      first()
    )
    .subscribe((result) => {
      this.reports = increment ? [...this.reports, ...result.data] : result.data ;
      this.length = result.link.total;
      if(this.reports.length>0) {
        total = this.reports[this.reports.length-1].total_unread;
      }
      this.total$.next(total);
      this.reportsData.next(this.reports);
      this.reportsLength.next(this.length);
    });
  }

  getReportsLengthObservable() {
    return this.reportsLength.asObservable();
  }

  getReports(){
    return this.reports;
  }

  decrementTotal() {
    this.total$.next(this.total$.value-1);
  }

  getReportsObservable(): Observable<object> {
    return this.reportsData.asObservable();
  }


  listTotal(): Observable<Resource<ReportModel>> {
    return this.resourceService.list<ReportModel>(this.urlService.get('REPORTS.REPORTS', {}));
  }

  updateReadReport(id: number): Observable<Resource<ReportModel>> {
    return this.resourceService.update<ReportModel>(this.urlService.get('REPORTS.REPORTS_READ', { id }), { });
  }

  delete(id: number) {
    return this.resourceService.delete(this.urlService.get('REPORTS.REPORT_ID', {id}));
  }

}

import { HttpClient } from '@angular/common/http';
import { NgModule, Optional, SkipSelf, InjectionToken } from '@angular/core';

import { Store } from './state/store';
import { FiUrlService } from './url.service';
import { FiShareModule } from '@fi-sas/share';
import { FiResourceService } from './resource.service';
import { FiConfiguratorModule, FiConfigurator } from '@fi-sas/configurator';
import { FiStorageService } from './storage.service';

export interface GenericType {
  [key: string]: any;
}

export type CoreState<C> = { [K in keyof C]: C[K] };

export const DEFAULT_STATE = {};
export const StateToken = new InjectionToken<GenericType>(
  '[INIT STATE] Initial state'
);
export const ApplicationState = new InjectionToken<CoreState<GenericType>>(
  '[STATE] Application state'
);

export function factoryState(
  state: CoreState<GenericType>
): Store<CoreState<GenericType>> {
  return Store.create(state);
}

@NgModule({
  imports: [FiShareModule, FiConfiguratorModule],
  providers: [
    { provide: StateToken, useValue: DEFAULT_STATE },
    { provide: FiUrlService, useClass: FiUrlService, deps: [FiConfigurator] },
    { provide: ApplicationState, useFactory: factoryState, deps: [StateToken] },
    {
      provide: FiResourceService,
      useClass: FiResourceService,
      deps: [HttpClient, FiConfigurator]
    },
    { provide: FiStorageService, useClass: FiStorageService }
  ]
})
export class FiCoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: FiCoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'FiCoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}

import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, first } from "rxjs/operators";
import { Router } from "@angular/router";
import { FiConfigurator } from "@fi-sas/configurator";
import { NzModalRef, NzModalService, NzModalState } from "ng-zorro-antd/modal";
import {
  MessageType,
  UiService,
} from "@fi-sas/webpage/core/services/ui.service";
import { NoConnectionComponent } from "@fi-sas/webpage/shared/components/no-connection/no-connection.component";
import { SystemErrorComponent } from "@fi-sas/webpage/shared/components/system-error/system-error.component";
import { AuthService } from "@fi-sas/webpage/auth/services/auth.service";

@Injectable()
export class ApisErrorsInterceptor implements HttpInterceptor {

  offLineModal: NzModalRef = null;
  modalError: NzModalRef = null;

  constructor(
    private uiService: UiService,
    private modalService: NzModalService,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService
  ) {

  }

  createOfflineModal() {
    if (this.offLineModal) {
      if (this.offLineModal.getState() !== NzModalState.OPEN) {
        this.offLineModal = null;
      }
    }
    if (!this.offLineModal) {
      this.offLineModal = this.modalService.create({
        nzWidth: 400,
        nzMaskClosable: false,
        nzClosable: false,
        nzContent: NoConnectionComponent,
        nzFooter: [
          {
            label: "Verificar",
            onClick: (componentInstance) => {
              componentInstance.verifyConnection();
            },
          },
        ],
      });
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let noError = false;
    if (request.headers.has("no-error")) {
      noError = true;
      request = request.clone({ headers: request.headers.delete("no-error") });
    }

    // if the call fails it retry until 5 times before explodes
    // return next.handle(request).pipe(retry(1), catchError(error => {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (!navigator.onLine) {
            this.createOfflineModal();
          } else {
            const httpErrorCode = error.status;
            switch (httpErrorCode) {
              case 401:
                if (!noError) {
                  this.authService.logout().pipe(first()).subscribe();
                  this.router.navigateByUrl("/auth/login");
                }
                break;
              case 403:
                this.router.navigateByUrl("/unauthorized");
                break;
              case 503:
                this.showModalError("SHARED.WEBPAGE_ERROR.ERROR503", "SHARED.WEBPAGE_ERROR.ERROR503_DESC");
                /*const ServiceUnavailable = this.modalService.create({
                  nzWidth: 416,
                  nzMaskClosable: false,
                  nzClosable: true,
                  nzContent: SystemErrorComponent,
                  nzFooter: null,
                  nzComponentParams: {
                    messageTitle: "SHARED.WEBPAGE_ERROR.ERROR503",
                    message: "SHARED.WEBPAGE_ERROR.ERROR503_DESC",
                  },
                });*/
                // this.showError('ERRORS.SERVICE_DOWN');
                break;
              case 500:
                this.showModalError("SHARED.WEBPAGE_ERROR.ERROR500", "SHARED.WEBPAGE_ERROR.ERROR500_DESC");
                /*const InternalServerError = this.modalService.create({
                  nzWidth: 416,
                  nzMaskClosable: false,
                  nzClosable: true,
                  nzContent: SystemErrorComponent,
                  nzFooter: null,
                  nzComponentParams: {
                    messageTitle: "SHARED.WEBPAGE_ERROR.ERROR500",
                    message: "SHARED.WEBPAGE_ERROR.ERROR500_DESC",
                  },
                })*/;
                break;
              case 0:
                if (!noError) {
                  /*this.showModalError("SHARED.WEBPAGE_ERROR.ERROR0", "SHARED.WEBPAGE_ERROR.ERROR0_DESC");
                  const errorDomain = this.modalService.create({
                    nzWidth: 416,
                    nzMaskClosable: false,
                    nzClosable: true,
                    nzContent: SystemErrorComponent,
                    nzFooter: null,
                    nzComponentParams: {
                      messageTitle: "SHARED.WEBPAGE_ERROR.ERROR0",
                      message: "SHARED.WEBPAGE_ERROR.ERROR0_DESC",
                    },
                  });*/
                }
                // this.showError('ERRORS.SERVICE_DOWN');
                break;
              case 404:
                break;
              case 400:
              case 422:
                if (error.error.errors && !noError) {
                  error.error.errors.map((error) => {
                    const language_id =
                      this.translateService.defaultLang === "pt" ? 3 : 4;
                    const messageTranslated = error.translations.find(
                      (et) => et.language_id === language_id
                    );
                    this.showError(
                      messageTranslated
                        ? messageTranslated.message
                        : error.translations[0].message
                    );
                  });
                }
                break;
              default:
                this.showError("ERRORS.REFRESH_PAGE");
            }
          }

        }
        return throwError(() => error);
      })
    );
  }

  private showError(message: string) {
    this.translateService.get(message).subscribe((translation) => {
      this.uiService.showMessage(MessageType.error, translation, {});
    });
  }

  private showModalError(messageTitle, message) {

    if (this.modalError) {
      if (this.modalError.state === NzModalState.CLOSED) {
        this.modalError = null;
      }
    }

    if (!this.modalError) {
      this.modalError = this.modalService.create({
        nzWidth: 416,
        nzMaskClosable: false,
        nzClosable: true,
        nzContent: SystemErrorComponent,
        nzFooter: null,
        nzComponentParams: {
          messageTitle,
          message,
        },
      });
    }
  }
}

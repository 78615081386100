import { Injectable } from "@angular/core";
import { FiResourceService, FiUrlService, Resource } from "@fi-sas/core";
import { AuthService } from "@fi-sas/webpage/auth/services/auth.service";
import { ResevationCountModel } from "@fi-sas/webpage/modules/alimentation/models/reservation.model";
import { BehaviorSubject, Observable } from "rxjs";
import { first } from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})

export class ReservationCountService {
    total$ = new BehaviorSubject<number>(0);

    constructor(private resourceService: FiResourceService,
        private urlService: FiUrlService, private authService: AuthService) {
        this.authService.getIsLoggedObservable().subscribe((isLogged) => {
            if (!isLogged) {
                this.total$ = new BehaviorSubject<number>(0);
            } else {
                this.loadData();
            }
        })
    }

    userReservationsCount(): Observable<Resource<ResevationCountModel>> {
        return this.resourceService.read<ResevationCountModel>(this.urlService.get('ALIMENTATION.RESERVATIONS_COUNT', {}), {});
    }

    loadData() {
        let total = 0;
        this.userReservationsCount().pipe(
            first()
        )
            .subscribe((result) => {
                total = result.data[0].total;
                this.total$.next(total);
            });
    }


}
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { pt_PT } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import pt from '@angular/common/locales/pt';
import { AppResolver } from './app.resolver';
import { AuthService } from './auth/services/auth.service';
import { WebpageErrorHandle } from './shared/errors/error-handle';
import { TokenInterceptor } from './shared/interceptor/token.interceptor';
import { ApisErrorsInterceptor } from './shared/interceptor/apis-errors.interceptor';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';
import { FiCoreModule } from './libs/core';
import { CookieModule } from 'ngx-cookie';
import { CoreModule } from './core/core.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SystemErrorModule } from './shared/components/system-error/system-error.module';
import { NoConnectionModule } from './shared/components/no-connection/no-connection.module';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzModalModule } from 'ng-zorro-antd/modal';

import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CardPaymentModule } from './modules/current-account/pages/card-payment/card-payment.module';
import { CurrentAccountsService } from './core/services/current-accounts.service';
import { RgpdService } from './core/rgpd/rgpd.service';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(
    httpClient,
    './assets/i18n/',
    `.json?version=${environment.version}`,
  );
}

export function initializeApp(authService: AuthService) {
  return (): Promise<any> => {
    return authService.refreshToken().toPromise();
  }
}


registerLocaleData(pt);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FiCoreModule,
    SystemErrorModule,
    NoConnectionModule,
    CookieModule.withOptions(),
    NzIconModule.forRoot(icons),

    NzNotificationModule,
    NzModalModule,
    NzNotificationModule,
    NzButtonModule,
    CoreModule,


    NzSpinModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CardPaymentModule,
  ],
  providers: [
    AuthService,
    CurrentAccountsService,
    RgpdService,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthService], multi: true },
    { provide: ErrorHandler, useClass: WebpageErrorHandle },
    { provide: NZ_I18N, useValue: pt_PT },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApisErrorsInterceptor, multi: true, },

    AppResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TermsAndPolicyModel } from '@fi-sas/webpage/pages/regulations/models/terms-and-policy.model';

@Component({
  selector: 'app-view-terms',
  templateUrl: './view-terms.component.html',
  styleUrls: ['./view-terms.component.less']
})
export class ViewTermsComponent implements OnInit {

  @Input() terms: TermsAndPolicyModel

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
  }

  getHTML(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }


}

import { Injectable } from '@angular/core';
import { UiService } from '@fi-sas/webpage/core/services/ui.service';
import { AuthService } from '@fi-sas/webpage/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarOptionsChangeService {

  constructor(private uiService: UiService,
    private authService: AuthService) {

    this.authService.getIsLoggedObservable().subscribe(val => {
      this.optionsAccomodation(val);

    });

    this.optionsAccomodation(this.authService.getIsLogged());
  }

  optionsChange() {
    this.optionsAccomodation(this.authService.getIsLogged());
  }

  optionsAccomodation(isLogged = false) {
    if (isLogged) {
      this.uiService.disableSideOption(false, 'ACCOMMODATION', 'RESIDENCES');
      this.uiService.disableSideOption(false, 'ACCOMMODATION', 'VIEW_APPLICATIONS');
      this.uiService.disableSideOption(false, 'ACCOMMODATION', 'CHANGE_REQUESTS');
      this.uiService.disableSideOption(false, 'ACCOMMODATION', 'CHANGE_REQUESTS_HISTORY');
      this.uiService.disableSideOption(false, 'ACCOMMODATION', 'BILLINGS');

      this.uiService.disableSideOption(false, 'ALIMENTATION', 'MENU');
      this.uiService.disableSideOption(false, 'ALIMENTATION', 'MENU_PACK');
      this.uiService.disableSideOption(false, 'ALIMENTATION', 'FOOD_ALLERGIES');
      this.uiService.disableSideOption(false, 'ALIMENTATION', 'PURCHASED_TICKETS');

      this.uiService.disableSideOption(false, 'PRIVATEACCOMMODATION', 'LIST');
      this.uiService.disableSideOption(false, 'PRIVATEACCOMMODATION', 'OWNER');

      this.uiService.disableSideOption(false, 'CURRENT_ACCOUNT', 'CURRENT_ACCOUNT');

      this.uiService.disableSideOption(false, 'U_BIKE', 'DASHBOARD');
      this.uiService.disableSideOption(false, 'U_BIKE', 'APPLICATION');
      this.uiService.disableSideOption(false, 'U_BIKE', 'FORMS');
      this.uiService.disableSideOption(false, 'U_BIKE', 'OCCURRENCES');

      this.uiService.disableSideOption(false, 'SOCIAL_SUPPORT', 'SOCIAL_SUPPORT_LIST');
      this.uiService.disableSideOption(false, 'SOCIAL_SUPPORT', 'SOCIAL_SUPPORT_APPLICATION');
      this.uiService.disableSideOption(false, 'SOCIAL_SUPPORT', 'SOCIAL_SUPPORT_EXPRESS');
      this.uiService.disableSideOption(false, 'SOCIAL_SUPPORT', 'SOCIAL_SUPPORT_OFFER');

      this.uiService.disableSideOption(false, 'VOLUNTEERING', 'VOLUNTEERING_LIST');
      this.uiService.disableSideOption(false, 'VOLUNTEERING', 'VOLUNTEERING_APPLICATION');
      this.uiService.disableSideOption(false, 'VOLUNTEERING', 'VOLUNTEERING_EXPRESS');
      this.uiService.disableSideOption(false, 'VOLUNTEERING', 'VOLUNTEERING_ACTIONS');

      this.uiService.disableSideOption(false, 'BUS', 'MOBILITY_ROUTE');
      this.uiService.disableSideOption(false, 'BUS', 'MOBILITY_SUB23');
      this.uiService.disableSideOption(false, 'BUS', 'MOBILITY_APPLICATION');

      this.uiService.disableSideOption(false, 'QUEUE', 'SERVICES');
      this.uiService.disableSideOption(false, 'QUEUE', 'MY_TIKETS');

      this.uiService.disableSideOption(false, 'HEALTH', 'HEALTH');
      this.uiService.disableSideOption(false, 'REQUESTS', 'REQUESTS');
    } /*else {
      this.uiService.disableSideOption(true, 'ACCOMMODATION', 'RESIDENCES');
      this.uiService.disableSideOption(true, 'ACCOMMODATION', 'VIEW_APPLICATIONS');

      this.uiService.disableSideOption(false, 'PRIVATEACCOMMODATION', 'LIST');
      this.uiService.disableSideOption(false, 'PRIVATEACCOMMODATION', 'OWNER');

      this.uiService.disableSideOption(true, 'ALIMENTATION', 'FOOD_ALLERGIES');
      this.uiService.disableSideOption(true, 'ALIMENTATION', 'PURCHASED_TICKETS');

      this.uiService.disableSideOption(true, 'U_BIKE', 'APPLICATION');
    }*/
  }
}

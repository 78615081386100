import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '@fi-sas/webpage/shared/services/cart.service';
import { first } from 'rxjs/operators';
import { UiService, MessageType } from '@fi-sas/webpage/core/services/ui.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.less']
})
export class ShoppingCartComponent implements OnInit {

  total = 0;
  cartObjects = [];
  cartTotalPrice = 0;
  visible = false;
  constructor(
    public cartService: CartService,
    private UiService:UiService,
    private translateService:TranslateService,
    private router: Router
  ) {
    this.loadData();
  }

  ngOnInit() {
  }

  loadData() {
    this.cartService.cartTotal$.subscribe(
      (total) => {
        this.total = total;
      }
    );
    this.cartService.cartData$.subscribe(
      (cartObjects) => {
        this.cartObjects = cartObjects;
      }
    );
    this.cartService.cartTotalPrice$.subscribe(
      (cartTotalPrice) => {
        this.cartTotalPrice = cartTotalPrice;
      }
    );

    this.cartService.getDataNotification();
  }

  clearCart() {
    this.cartObjects.forEach((cart)=> {
      this.cartService.removeItemsCart(cart.account_id).pipe(
        first()
      ).subscribe(() => {
        this.loadData();
      })
    })

  }

  quantityChange(id: string, quantity: number, action: string, max_quantity) {
    switch (action) {
      case 'INCREMENT':
        quantity = quantity + 1;
        if(max_quantity < quantity) {
          this.UiService.showMessage(
            MessageType.error,
            this.translateService.instant('ALIMENTATION.STOCK')
          );
        }else {
          this.addToCartQtd(id, quantity);
        }

        break;

      case 'DECREMENT':
        quantity = quantity - 1;
        if(quantity == 0) {
          this.removeItemCard(id);
        }else {
          this.addToCartQtd(id, quantity);
        }
        break;

      case 'REMOVE':
        this.removeItemCard(id);
        break;
  }
}

  goToCart() {
    this.visible = false;
    this.router.navigate(['shopping-cart']);
  }


  addToCartQtd(id: string, quantity: number) {
    this.cartService.changeQtdItem(id, quantity).pipe(first()).subscribe(()=> {
      this.cartService.getDataNotification();
      this.loadData();
    })
  }

  removeItemCard(idItem) {
    this.cartService.removeItemCart(idItem).pipe(first()).subscribe(() => {
      this.cartService.getDataNotification();
      this.loadData();
    });
  }

}

import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";


@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html'
})
export class LogoComponent implements OnInit {
    imgIP!: string;

    ngOnInit() {
        this.imgIP = 'assets/logos/color/' + environment.institute + '.svg';
    }

}

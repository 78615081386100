<div class="">
  <button nz-button nzType="link" class="shopping-cart__btn" nz-popover [(nzPopoverVisible)]="visible"
    [nzPopoverTitle]="'SHOPPING_CART.NOTIFICATION.TITLE' | translate" nzPopoverTrigger="click"
    [nzPopoverContent]="contentTemplate">
    <nz-badge [nzCount]="total" [nzOverflowCount]="99">
      <i nz-icon nzType="icons:icons-cart" style="font-size:22px" nzTheme="outline" class="header-icon"></i>
    </nz-badge>
  </button>
</div>

<ng-template #contentTemplate style="width: 310px!important;">
  <nz-list [nzDataSource]="cartObjects" [nzRenderItem]="defaultItem"
    style="max-height: 300px; overflow-y: scroll; width: 100%; max-width: 310px;">
    <ng-template #defaultItem let-cart>
      <div *ngFor="let item of cart.items">
        <nz-list-item>
          <div nz-row class="cart-dropdown__item">
            <div nz-col [nzSpan]="24" class="cart-dropdown__item__plate">
              {{item.name}}
              <span *ngIf="item.article_type === 'REFECTORY'">- {{item.extra_info.refectory_meal_category == "dinner" ?
                ("DASHBOARD.WIDGETS.MEALS_LOGGED.DINNER" | translate) : ("DASHBOARD.WIDGETS.MEALS_LOGGED.LUNCH" |
                translate)}}</span>
            </div>
            <div nz-col [nzSpan]="22" class="cart-dropdown__item__place">
              {{item.location}}
            </div>
            <div nz-col [nzSpan]="2" class="cart-dropdown__item__place">
              <i nz-icon nzType="close" nzTheme="outline" class="icon-delete-item" id="delete-item-cart"
                (click)="quantityChange(item.id, item.quantity, 'REMOVE', item.max_quantity)"></i>
            </div>
            <div *ngIf="item?.extra_info?.refectory_consume_at" nz-col [nzSpan]="24" class="cart-dropdown__item__date">
              {{("DASHBOARD.WIDGETS.MEALS_LOGGED.DATE" | translate)}} {{item?.extra_info?.refectory_consume_at | date:
              'dd-MM-yyyy'}}
            </div>

            <div nz-col [nzSpan]="18">

              <div class="box-cart">
                <i nz-icon nzType="icons:icons-minus" class="icon__minus" nzTheme="outline"
                  (click)="quantityChange(item.id, item.quantity, 'DECREMENT', item.max_quantity)"></i>
                <span class="quantity">
                  {{item?.quantity}}
                </span>
                <i nz-icon nzType="icons:icons-plus" class="icon__plus" nzTheme="outline"
                  (click)="quantityChange(item.id, item.quantity, 'INCREMENT', item.max_quantity)"></i>
              </div>
            </div>

            <div nz-col [nzSpan]="6" class="cart-dropdown__item__price"> {{(item.total_value) | currency : 'EUR'}}
            </div>

          </div>
        </nz-list-item>

      </div>
    </ng-template>
  </nz-list>

  <div *ngIf="cartObjects.length>0" class="cart-dropdown__buttom">
    <p class="cart-dropdown__buttom__total">
      <span class="cart-dropdown__buttom__total__text">
        {{ 'SHOPPING_CART.NOTIFICATION.TOTAL' | translate }}
      </span>
      <span class="cart-dropdown__buttom__total__text cart-dropdown__buttom__total__amount">
        {{(cartTotalPrice?cartTotalPrice:0) | currency : 'EUR'}}
      </span>
    </p>
    <div class="cart-dropdown__buttom__btn">
      <button class="button" nz-button nzShape="round" (click)="goToCart()" class="cart-dropdown__place-order-btn">
        {{'SHOPPING_CART.NOTIFICATION.BTN' | translate}}
      </button>
      <button *ngIf="cartObjects.length>0" nz-button type="button" class="button-style-clear" nz-tooltip
        [nzTooltipTitle]="'ALIMENTATION.CLEAR' | translate" (click)="clearCart()">
        <i nz-icon nzType="delete" nzTheme="outline" style="font-size: 16px;" id="icon-delete-cart"></i>
      </button>
    </div>
  </div>
</ng-template>
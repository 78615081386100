<!--Sidebar Mobile-->
<nz-drawer [nzClosable]="false" [nzVisible]="!($siderDrawerCollapsed | async)" [nzPlacement]="placement" nzTitle="Menu"
  (nzOnClose)="closeSideMenu()" [nzWidth]="300">
  <ng-container *nzDrawerContent>
    <ul class="menu">

      <li class="menu__group">
        <div [routerLink]="['/']">
          <i class="menu__group__icon" nz-icon nzType="icons:icons-services"></i>
          <div class="title">{{'MISC.DASHBOARD' | translate}}</div>

        </div>
      </li>

      <ng-container *ngFor="let item of siderItems">

        <li class="menu__group" [class.disabled]='item.disable'>
          <div (click)="selectModule(item)">
            <nz-badge *ngIf="item.serviceId == 2" [nzStyle]="{ backgroundColor: '#d0021b', size: '10px' }"
              [nzCount]="total">
              <i class="menu__group__icon" id="icon-menu-start-mobile" nz-icon [nzType]="item?.icon"></i>
            </nz-badge>
            <i *ngIf="item.serviceId != 2" class="menu__group__icon" id="icon-menu-start-mobile" nz-icon
              [nzType]="item?.icon"></i>
            <div class="title">{{item?.name ? ((item?.name | languageChange)?.name) : ''}}</div>
          </div>
          <ul>
            <ng-container *ngFor="let children of item.children">
              <li *ngIf="children.visible" class="menu__item" [class.disabled]='children.disable'
                [routerLink]="[children.link]">{{children.name | translate}}</li>
            </ng-container>
          </ul>
        </li>

      </ng-container>

      <li class="menu__group menu__language">

        <div nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'">
          <a>
            <i class="menu__group__icon" nz-icon nzType="icons:icons-language"></i>
            <span *ngIf="!($siderDrawerCollapsed | async)" class="nav-text sidebar__lang__text title">{{
              'CORE.LANGUAGES.'
              +
              translate.currentLang | translate }}</span>

          </a>
        </div>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item *ngFor="let lang of translate.getLangs()" (click)="langChange(lang)"
              [nzSelected]="translate.currentLang === lang">
              {{ 'CORE.LANGUAGES.' + lang | translate }}
            </li>
          </ul>
        </nz-dropdown-menu>

      </li>
    </ul>
  </ng-container>
</nz-drawer>
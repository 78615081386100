export * from './core.module';
export * from './state/store';
export * from './state/types';
export * from './state/action';

export { FiUrlService, DomainHostType } from './url.service';
export {
  FiResourceService,
  ResourceError,
  HttpOptions,
  Resource,
  Link
} from './resource.service';
export { FiStorageService } from './storage.service';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'HourConvert',
})
export class HourConvertPipe implements PipeTransform {
  transform(hour: string | number): string {
    if (hour) {
      let minutes: string;
      let hourString = hour.toString();
      const hourSplitter = hourString.split('.');

      if (hourSplitter.length === 2) {
        minutes =
          hourSplitter[1].length === 2 ? hourSplitter[1] : `${hourSplitter[1]}0`;
        hourString = `${hourSplitter[0]}h${minutes}`;
      } else {
        hourString = `${hourSplitter[0]}h`;
      }
      return hourString;
    } else {
      return '0';
    }
  }
}

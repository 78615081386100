import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { CurrentAccountsService } from "../../services/current-accounts.service";
import { Subject, distinct, takeUntil } from "rxjs";

@Component({
    selector: 'app-layout-current-account',
    templateUrl: './current-account.component.html',
    styleUrls: ['./current-account.component.less']
})

export class LayoutCurrentAccountComponent implements OnInit, OnDestroy, AfterViewInit {
    visibleCurrentAccounts: boolean;
    loading = true;
    currentAccounts = null;

    $destroy = new Subject();

    constructor(private currentAccountsService: CurrentAccountsService) {
    }

    ngOnInit() {
        setTimeout(() => {
            this.getCurrentAccounts();
        }, 200);
    }

    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }

    ngAfterViewInit(): void {
        
    }

    clickMe(): void {
        this.visibleCurrentAccounts = false;
    }

    getCurrentAccounts() {
        this.currentAccountsService
            .getCurrentAccountObservable()
            .pipe(
                takeUntil(this.$destroy),
            ).subscribe((data) => {
                this.currentAccounts = data;
                this.loading = false;
            });
    }



    refresh() {
        this.loading = true;
        setTimeout(() => {
            this.currentAccountsService.loadData();
        }, 200);
    }




}
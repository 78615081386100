import { Component, OnInit } from "@angular/core";
import { first } from "rxjs/operators";
import { NotificationModel } from "../../models/notifications.model";
import { NotificationsService } from "../../services/notifications.service";

@Component({
    selector: 'app-layout-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.less']
})

export class LayoutNotificationsComponent implements OnInit {

    visibleNotifications: boolean;
    $notifications = null;
    $notificationsLength = null;
    loading = true;
    notificationSelect: NotificationModel = new NotificationModel();
    total = 0;

    constructor(private notificationsService: NotificationsService) {
        this.notificationSelect = new NotificationModel();
        this.getNotifications();
        this.getNotificationsLength();
        this.notificationsService.total$.subscribe(
            (total) => {
              this.total = total;
            }
          );
    }

    ngOnInit() {
        
    }

    getNotifications() {
        this.$notifications = null;
        this.$notifications = this.notificationsService
            .getNotificationsObservable();
        this.loading = false;
    }

    getNotificationsLength() {
        this.$notificationsLength = null;
        this.$notificationsLength = this.notificationsService
            .getNotificationsLengthObservable();
    }

    refresh() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
            this.notificationSelect = new NotificationModel();
            this.notificationsService.loadData();
          }, 200);
    }

    clickMe(): void {
        this.visibleNotifications = false;
    }

    showMore() {
        this.notificationsService.loadData(true);
    }

    selectNotification(notification: NotificationModel) {
        this.notificationSelect = notification;
        if(notification.unread == true) {
            this.notificationsService.updateReadNotification(notification.id).pipe(first()).subscribe(()=> {
                notification.unread = false;
                this.notificationsService.decrementTotal();
            });
        }
        
    }

    changeIcon(service_id:number): string {
        switch (service_id) {
            case 1: {
                return 'icons:icons-accomodation';
            }
            case 2: {
                return 'icons:icons-food';
            }
            case 3: {
                return 'icons:icons-mobility';
            }
            case 4: {
                return 'icons:walltet_v3';
            }
            case 7: {
                return 'icons:icons-health';
            }
            case 11: {
                return 'icons:walltet_v3';
            }
            case 13: {
                return 'icons:icons-ubike';
            }
            case 15: {
                return 'icons:icons-pin-a';
            }
            case 17: {
                return 'icons:icons-scholarship';
            }
            case 18: {
                return 'icons:icons-calendar';
            }
            case 21: {
                return 'icons:icons-ticket';
            }
            case 28: {
                return 'icons:icons-voluntariado_v3';
            }
            default: {
                return 'icons:icons8-file';
              
            }
        }
    }

    delete(id) {
        this.notificationsService.delete(id).pipe(first()).subscribe((data)=> {
            this.refresh();
        })
    }
}


<div class="auth" nz-row nzType="flex" nzAlign="top" nzJustify="center">
  <div nz-col nzSpan="24">
    <div nz-row class="auth__logo"><b>SAS</b>ocial</div>
    <div nz-row nzType="flex" nzAlign="middle" nzJustify="center">
      <nz-card nz-col nzXs="22" nzSm="22" nzMd="20" nzLg="16" nzXl="12">
        <main>
          <router-outlet></router-outlet>
        </main>
      </nz-card>
    </div>
  </div>
</div>
    <nz-footer class="auth__footer">
      <app-layout-footer></app-layout-footer>
    </nz-footer>

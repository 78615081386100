import { Injectable } from '@angular/core';
import { AuthService } from '@fi-sas/webpage/auth/services/auth.service';
import { SiderItem } from '../../models/sider-item';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  constructor(private authService: AuthService) {}

  getSideBarItems(): SiderItem[] {
    return [
      {
        code: 'ACCOMMODATION',
        name: 'DASHBOARD.BUTTON.ACCOMMODATION',
        link: '/accommodation',
        icon: 'icons:icons-accomodation',
        serviceId: 1,
        children: [
          { code: 'RESIDENCES', name: 'ACCOMMODATION.LIST_RESIDENCES.RESIDENCES', link: '/accommodation/list-residences', disable: false, scope: 'accommodation:residences:read' },
          { code: 'VIEW_APPLICATIONS', name: 'ACCOMMODATION.VIEW_APPLICATIONS', link: '/accommodation/list-applications', disable: false, scope: 'accommodation:applications:read' },
        ],
        scope: 'accommodation'
      },
      {
        code: 'PRIVATEACCOMMODATION',
        name: 'DASHBOARD.BUTTON.PRIVATEACCOMMODATION',
        link: '/privateaccommodation',
        icon: 'icons:icons-pin-a',
        serviceId: 15,
        children: [
          { code: 'LIST', name: 'PRIVATEACCOMMODATION.LIST_HOST.HOST', link: '/privateaccommodation/list-host', disable: false, scope: 'private_accommodation:listings:read' },
          { code: 'OWNER', name: 'PRIVATEACCOMMODATION.OWNER_AREA.OWNER', link: '/privateaccommodation/owner-area', disable: false, scope: 'private_accommodation:owners' },
        ],
        scope: 'private_accommodation'
      },
      {
        code: 'ALIMENTATION',
        name: 'DASHBOARD.BUTTON.ALIMENTATION',
        link: '/alimentation',
        icon: 'icons:icons-food',
        serviceId: 2,
        children: [
          { code: 'MENU', name: 'ALIMENTATION.MEALS_MENU', link: '/alimentation/menu', disable: false },
          { code: 'MENU_PACK', serviceId: 16, name: 'ALIMENTATION.MEALS_MENU_PACKS', link: '/alimentation/menu_pack', disable: false },
          { code: 'FOOD_ALLERGIES', name: 'ALIMENTATION.USER_ALLERGENS.FOOD_ALLERGIES', link: '/alimentation/user-allergens', disable: false },
          {
            code: 'PURCHASED_TICKETS',
            name: 'ALIMENTATION.PURCHASED_TICKETS.PURCHASED_TICKETS',
            link: '/alimentation/purchased-tickets',
            disable: false
          }
        ],
        scope: 'alimentation'
      },
      {
        code: 'CURRENT_ACCOUNT',
        name: 'DASHBOARD.BUTTON.CURRENT_ACCOUNT',
        link: '/current-account',
        icon: 'icons:walltet_v3',
        serviceId: 11,
        children: [
          { code: 'CURRENT_ACCOUNT', name: 'CURRENT_ACCOUNT.ACCOUNT', link: '/current-account/accounts', disable: false }
        ],
        scope: 'current_account'
      },
      {
        code: 'U_BIKE',
        name: 'DASHBOARD.BUTTON.UBIKE',
        link: '/u-bike',
        icon: 'icons:icons-ubike',
        serviceId: 13,
        children: [
          { code: 'DASHBOARD', name: 'U_BIKE.DASHBOARD.TITLE', link: '/u-bike/dashboard', disable: false, scope: 'u_bike' },
          { code: 'APPLICATION', name: 'U_BIKE.DASHBOARD.APPLICATIONS_CONTAINER.TITLE', link: '/u-bike/application', disable: false, scope: 'u_bike:applications:read' },
          { code: 'FORMS', name: 'U_BIKE.FORMS.TITLE', link: '/u-bike/forms', disable: false, scope: 'u_bike:application-forms' },
          { code: 'OCCURRENCES', name: 'U_BIKE.DASHBOARD.OCCURRENCES_CONTAINER.TITLE', link: '/u-bike/occurrences', disable: false, scope: 'u_bike:application-forms' },

        ],
        scope: 'u_bike'
      },
      {
        code: 'SOCIAL_SUPPORT',
        name: [],
        link: '/social-support',
        icon: 'icons:icons-scholarship',
        serviceId: 17,
        children: [
          { code: 'SOCIAL_SUPPORT_LIST', name: 'SOCIAL_SUPPORT.COLABORATION_LIST', link: '/social-support/experiences', disable: false, scope: 'social_scholarship:experiences:list' },
          { code: 'SOCIAL_SUPPORT_APPLICATION', name: 'SOCIAL_SUPPORT_MENU.FIELD2', link: '/social-support/application-status-history', disable: false, scope: 'social_scholarship:applications:list' },
          { code: 'SOCIAL_SUPPORT_EXPRESS', name: 'SOCIAL_SUPPORT_MENU.FIELD3', link: '/social-support/express-interest-status-history', disable: false, scope: ['sasocial:is_student', 'social_scholarship:experience-user-interest:create'] },
          { code: 'SOCIAL_SUPPORT_OFFER', name: 'SOCIAL_SUPPORT_MENU.FIELD4', link: '/social-support/offers-status-history', disable: false, scope: 'social_scholarship:experiences:my_offers' },
        ],
        scope: 'social_scholarship'
      },
      {
        code: 'VOLUNTEERING',
        name: 'DASHBOARD.BUTTON.VOLUNTEERING',
        link: '/volunteering',
        icon: 'icons:icons-voluntariado_v3',
        serviceId: 28,
        children: [
          { code: 'VOLUNTEERING_LIST', name: 'VOLUNTEERING.VOLUNTEERING_ACTIONS', link: '/volunteering/experiences', disable: false, scope: 'volunteering:experiences:list' },
          { code: 'VOLUNTEERING_APPLICATION', name: 'VOLUNTEERING.MENU.MY_APPLICATIONS', link: '/volunteering/application-status-history', disable: false, scope: 'volunteering:applications:list' },
          { code: 'VOLUNTEERING_EXPRESS', name: 'VOLUNTEERING.MENU.MY_INTERESTS', link: '/volunteering/express-interest-status-history', disable: false, scope: 'volunteering:experience-user-interests:list' },
          { code: 'VOLUNTEERING_ACTIONS', name: 'VOLUNTEERING.MENU.ACTIONS_HISTORY', link: '/volunteering/actions-status-history', disable: false, scope: 'volunteering:experiences:my_offers' },
        ],
        scope: 'volunteering'
      },
      {
        code: 'BUS',
        name: 'DASHBOARD.BUTTON.MOBILITY',
        link: '/mobility',
        icon: 'icons:icons-mobility',
        serviceId: 3,
        children: [
          { code: 'MOBILITY_ROUTE', name: 'MOBILITY.ROUTE_SEARCH.TICKETS_SCHEDULES', link: '/mobility/route-search', disable: false, scope: 'bus:route_search' },
          { code: 'MOBILITY_SUB23', name: 'MOBILITY.ROUTE_SEARCH.SUB23_PASS_DECLARATION', link: '/mobility/sub-23-declarations', disable: false, scope: 'bus:sub23_declarations' },
          { code: 'MOBILITY_APPLICATION', name: 'MOBILITY.ROUTE_SEARCH.PASS_APPLICATION', link: '/mobility/applications', disable: false, scope: 'bus:applications' },
        ],
        scope: 'bus'
      },
      {
        code: 'QUEUE',
        name: 'DASHBOARD.BUTTON.QUEUE',
        link: '/queue',
        icon: 'icons:icons-ticket',
        serviceId: 21,
        children: [
          { code: 'SERVICES', name: 'QUEUE.TITLE_QUEUE', link: '/queue/list-queues', disable: false },
          { code: 'MY_TIKETS', name: 'QUEUE.TITLE_MY_TICKET', link: '/queue/my-tickets', disable: false }
        ],
        scope: 'queue'
      },
      {
        code: 'HEALTH',
        name: 'DASHBOARD.BUTTON.HEALTH',
        link: '/health',
        icon: 'icons:icons-health',
        serviceId: 7,
        children: [
          { code: 'HEALTH', name: 'HEALTH.HEALTH', link: '/health', disable: false }
        ],
        scope: 'health'
      },
      {
        code: 'CALENDAR',
        name: 'DASHBOARD.BUTTON.CALENDAR',
        link: '/calendar',
        icon: 'icons:icons-calendar',
        serviceId: 18,
        scope: 'calendar',
        children: []
      },
      {
        code: "EMERGENCY_FUND",
        name: "DASHBOARD.BUTTON.EMERGENCY_FUND",
        link: "/emergency-fund",
        icon: "icons:icons-emergency-fund",
        serviceId: 30,
        children: [
          {
            code: "EMERGENCY_FUND",
            name: "EMERGENCY_FUND.MENU.DASHBOARD",
            link: "/emergency-fund/home",
            disable: false,
          },
          {
            code: "APPLICATIONS",
            name: "EMERGENCY_FUND.MENU.APPLICATIONS",
            link: "/emergency-fund/list-applications",
            disable: false,
          },
          {
            code: "CHANGES",
            name: "EMERGENCY_FUND.MENU.CHANGES",
            link: "/emergency-fund/list-applications-changes",
            disable: false,
          },
          {
            code: "EXPENSES",
            name: "EMERGENCY_FUND.MENU.EXPENSES",
            link: "/emergency-fund/list-expenses",
            disable: false,
          },
        ],
        scope: "emergency_fund",
      },
      {
        code: 'REQUESTS',
        name: 'DASHBOARD.BUTTON.REQUESTS',
        link: '/requests',
        icon: 'form',
        serviceId: 33,
        children: [],
        scope: 'requests'
      },
      {
        code: 'MAINTENANCE',
        name: 'DASHBOARD.BUTTON.MAINTENANCE',
        link: '/maintenance',
        icon: 'tool',
        serviceId: 20,
        children: [
          { code: 'MAINTENANCE', name: 'MAINTENANCE.BAR_MENU.DASHBOARD', link: '/maintenance/dashboard', disable: false }
        ],
        scope: 'maintenance'
      },
      {
        code: 'JOB_PORTAL',
        name: 'DASHBOARD.BUTTON.JOB_PORTAL',
        link: this.validatePermissionsJobPortal(),
        icon: 'icons:icons-job-portal',
        serviceId: 35,
        children: [
          {
            code: "JOB_OFFERS",
            name: "JOB_PORTAL.BAR_MENU.JOB_PORTAL",
            link: "/job-portal/job-offers",
            disable: false,
          },
          {
            code: "EMPLOYMENT_EVENT",
            name: "JOB_PORTAL.BAR_MENU.EMPLOYMENT_EVENT",
            link: "/employment-events",
            disable: false,
          },


        ],
        scope: 'job_portal'
      },
      {
        code: 'CASUAL_ACCOMMODATION',
        name: 'DASHBOARD.BUTTON.CASUAL_ACCOMMODATION',
        link: '/casual-accommodation',
        icon: 'icons:icons-casual-accommodation',
        serviceId: 36,
        children: [
          { code: 'DISCOVER', name: 'CASUAL_ACCOMMODATION.BAR_MENU.DISCOVER', link: '/casual-accommodation/discover', disable: false, scope: 'casual-accommodation:reservations:create' },
        ],
        scope: 'casual-accommodation'
      },
    ];
  }

  private validatePermissionsJobPortal(): string {
    return this.authService.hasPermission("job_portal:applications:read")
      ? 'job-portal/my-applications'
      : '/job-portal/job-offers';
  }
}


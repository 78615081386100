
<div *ngIf="!payment">
  <nz-spin [nzSpinning]="true"></nz-spin>
</div>
<div *ngIf="payment">
  <nz-result
   *ngIf="payment.status !== 'PENDING'"
    [nzStatus]="payment.status == 'CONFIRMED' ? 'success' : 'error'"
    [nzTitle]="payment.status == 'CONFIRMED' ? 'Carregamento efetuado com sucesso!' : 'Não foi possivel completar o carregamento!'"
    [nzSubTitle]="payment.status == 'CONFIRMED' ? 'O saldo já se encontra disponivél na sua conta-corrente!' : 'Ocorreu um erro durante o processo de pagamento.'"
  >
    <div nz-result-extra>
      <button nz-button nzType="primary" [routerLink]="['/current-account']">Voltar as contas correntes</button>
    </div>
  </nz-result>
  
  <div *ngIf="payment.status == 'PENDING'">
      <form class="paymentSPG" [attr.spg-context]="sibsFormContext" [attr.spg-config]="sibsFormConfig | json" [attr.spg-style]="sibsFormStyle | json">
      </form>
  </div>
</div>
import { environment } from '../environments/environment';

export const WP_CONFIGURATION = {
  DEFAULT_LANG_ID: 3,
  DEFAULT_CATEGORY_ID: 1,
  DOMAINS_API: [
    { HOST: environment.host, KEY: '@api_gateway' },
    { HOST: environment.auth_api_url, KEY: '@api_auth' },
    { HOST: environment.conf_api_url, KEY: '@api_configuration' },
    { HOST: environment.accommodation_api_url, KEY: '@api_accommodation' },
    { HOST: environment.payments_api_url, KEY: '@api_payments' },
    { HOST: environment.media_api_url, KEY: '@api_media' },
    { HOST: environment.media_url, KEY: '@api_media_download' },
    { HOST: environment.alimentation_api_url, KEY: '@api_alimentation' },
    { HOST: environment.current_account_api_url, KEY: '@api_current_account' },

    { HOST: environment.social_support_api_url, KEY: '@api_social_support' },
    { HOST: environment.volunteering_api_url, KEY: '@api_volunteering' },
    { HOST: environment.mentoring_api_url, KEY: '@api_mentoring' },

    { HOST: environment.u_bike_api_url, KEY: '@api_u_bike' },
    { HOST: environment.private_accommodation_api_url, KEY: '@api_private_accommodation' },
    { HOST: environment.infrastructure_api_url, KEY: '@api_infrastructure' },
    { HOST: environment.mobility_api_url, KEY: '@api_mobility' },
    { HOST: environment.reports_api_url, KEY: '@api_reports' },
    { HOST: environment.notifications_api_url, KEY: '@api_notifications' },
    { HOST: environment.queues_api_url, KEY: '@api_queue' },
    { HOST: environment.communication_api_url, KEY: '@api_communication' },
    { HOST: environment.health_api_url, KEY: '@api_health' },
    { HOST: environment.calendar_api_url, KEY: '@api_calendar' },
    { HOST: environment.emergency_fund_api_url, KEY: "@api_emergency_fund" },
    { HOST: environment.requests_api_url, KEY: "@api_requests" },
    { HOST: environment.refill_h2o_api_url, KEY: "@api_refill_h2o" },
    { HOST: environment.maintenance_api_url, KEY: "@api_maintenance" },
    { HOST: environment.job_portal_api_url, KEY: "@api_job_portal" },
    { HOST: '/assets/data', KEY: '@local' },
  ],
  ENDPOINTS: {
    SIBS: {
      CARD_REDIRECT: '@api_gateway:/api/sibs/card_redirect?paymentId=:paymentId',
    },
    REPORTS: {
      REPORTS: '@api_reports:/reports',
      REPORT_ID: '@api_reports:/reports/:id',
      REPORTS_READ: '@api_reports:/reports/:id/read'
    },
    NOTIFICATIONS: {
      NOTIFICATIONS_INTERNAL: '@api_notifications:/notifications/internal',
      NOTIFICATIONS_ID: '@api_notifications:/notifications/:id',
      NOTIFICATIONS_READ: '@api_notifications:/notifications/:id/read',
    },
    EVENTS: {
      CATEGORIES: '@api_calendar:/categorys',
      EVENTS: '@api_calendar:/events',
      EVENTS_ID: '@api_calendar:/events/:id',
      SUBSCRIPTION: '@api_calendar:/event_subscriptions',
      REMOVE_SUBSCRIPTION: '@api_calendar:/event_subscriptions/cancel-subscription/:id',
      REMOVE_EXTERNAL_SUBSCRIPTION: '@api_calendar:/event_subscriptions/cancel-external-subscription',
      CALENDAR_EXPORT: '@api_calendar:/events/:id/export_calendar',
      CALENDAR_VIEW: '@api_calendar:/events/calendar-view'
    },
    QUEUE: {
      TICKETS_DIGITAL: '@api_queue:/tickets/user/S',
      MARKING: '@api_queue:/tickets/user/M',
      TICKET: '@api_queue:/tickets/:id',
      HISTORY_TICKETS_DIGITAL: '@api_queue:/tickets_history/user/S',
      HISTORY_MARKING: '@api_queue:/tickets_history/user/M',
      SUBJECTS: '@api_queue:/subjects',
      SERVICES: '@api_queue:/services',
      ISSUETICKET: '@api_queue:/tickets/sequential',
      APPOINTMENTS_SUBJECT: '@api_queue:/subjects/:id/appointments',
      APPOINTMENT: '@api_queue:/tickets/appointment',
    },
    COMMUNICATION: {
      LIST_NEWS: '@api_communication:/posts/webpage',
      LIST_NEWS_ID: '@api_communication:/posts/:id'
    },
    USERS: {
      CHANGE_PASSWORD: '@api_auth:/users/change-password',
      RANDOM_PIN: '@api_auth:/users/randomPin',
      CHANGE_PIN: '@api_auth:/users/change-pin',
      FIRST_LOGIN: '@api_auth:/users/first-login',
      UPDATE_ME: '@api_auth:/users/update-me',
      RESET_PIN: '@api_auth:/users/reset-pin',
      CONFIRM_PIN: '@api_auth:/users/confirm-pin',
      RESET_PASSWORD: '@api_auth:/users/reset-password',
      NEW_PASSWORD: '@api_auth:/users/new-password',
      USERS: '@api_auth:/users',
      USERS_ID: '@api_auth:/users/:id',
      VERIFY_ACCOUNT: '@api_auth:/users/verify_account',
      NEW_VERIFICATION_CODE: '@api_auth:/users/create_account_verify',
      USER_DISABLE: '@api_auth:/users/disable-account',
      DOCUMENT_TYPES: '@api_auth:/document-types'
    },
    CONFIGURATION: {
      COURSE_DEGREES: '@api_configuration:/course-degrees',
      COURSES: '@api_configuration:/courses',
      CONTACTS: '@api_configuration:/contacts',
      GERAL: '@api_configuration:/configuration',
      GERALSERVICE_ID: '@api_configuration:/services/:id',
      LANGUAGES: '@api_configuration:/languages',
      LANGUAGES_ID: '@api_configuration:/languages/:id',
      TEXTAPRESENTATION: '@api_configuration:/apresentation_texts',
      FORM_INFORMATION: '@api_configuration:/form_informations',
      DASHBOARD: '@api_configuration:/dashboard_user',
      TERMS: '@api_configuration:/terms',
      PRIVACY_POLICIES: '@api_configuration:/privacy_policies',
      INFORMATIONS: '@api_configuration:/informations',
      TERMS_ID: '@api_configuration:/terms/:id',
      PRIVACY_POLICIES_ID: '@api_configuration:/privacy_policies/:id',
      INFORMATIONS_ID: '@api_configuration:/informations/:id',
      ACCESSIBILITY: '@api_configuration:/accessibility',
      ACCESSIBILITY_ID: '@api_configuration:/accessibility/:id',
      DEVICES: '@api_configuration:/devices',
      DEVICES_ID: '@api_configuration:/devices/:id',
      ACADEMIC_YEARS: '@api_configuration:/academic_years',
      CURRENT_ACADEMIC_YEAR: '@api_configuration:/academic_years/current',
      HOLIDAYS: '@api_configuration:/holidays',
      CONSENT: '@api_configuration:/rgpd_consent',
      CURRENT_RGPD: '@api_configuration:/rgpd/:id',
      CONSENT_RGPD_STATUS: '@api_configuration:/rgpd_consent/status',
    },
    AUTH: {
      LOGIN: '@api_auth:/authorize/device-type/WEB',
      LOGIN_USER: '@api_auth:/authorize/user',
      LOGOUT: '@api_auth:/authorize/logout/:type',
      REFRESH_TOKEN: '@api_auth:/authorize/refresh-token/:type',
      VALIDATE_TOKEN: '@api_auth:/authorize/validate-token',
      SSO_URL: '@api_auth:/authorize/sso/url',
      CC_LOGIN: '@api_auth:/authorize/cc/WEB',
    },
    ACCOMMODATION: {
      CASUAL_ACCOMMODATION: {
        AVAIABLE_RESIDENCES: '@api_accommodation:/residences/availableCasualAccommodation',
        TYPOLOGIES_RESIDENCES: '@api_accommodation:/typologies/availableTypologies',
        RESIDENCE_INFO: '@api_accommodation:/residences/:id/medias',
        TYPE_HOSTS: '@api_accommodation:/reason_accommodation',
        RESERVATIONS: '@api_accommodation:/reservations',
        RESERVATIONS_ID: '@api_accommodation:/reservations/:id',
        CANCEL_RESERVATION: '@api_accommodation:/reservations/:id/cancel-reservation',
        RESERVATION_CHECK_IN: '@api_accommodation:/reservations/:id/check-in',
        AVAILABILITY_ANALYSIS: '@api_accommodation:/reservations/configurations',
        HORARY_RESIDENCE: '@api_accommodation:/schedule_residence_casual_accommodation',
      },
      INFORMATIONS_APPLICATIONS: '@api_accommodation:/application_informations',
      CONFIGURATIONS: '@api_accommodation:/configurations',
      PATRIMONY_CATEGORIES: '@api_accommodation:/patrimony-categories',
      PRINT: '@api_accommodation:/reports/application',
      APPLICATION_PHASE_OPEN: '@api_accommodation:/application-phases/openPhase',
      PERIODS: '@api_accommodation:/accommodation-periods',
      APPLICATIONS: '@api_accommodation:/applications',
      APPLICATIONS_ID: '@api_accommodation:/applications/:id',
      APPLICANT_INFO: '@api_accommodation:/applications/applicant-info',
      APPLICATION_STATUS: '@api_accommodation:/applications/application-status',
      APPLICATION_CHANGE_STATUS: '@api_accommodation:/applications/:id/status',
      APPLICATION_CHANGES: '@api_accommodation:/applications/:id/changes',
      APPLICATION_USER_CAN_APPLY: '@api_accommodation:/applications/can-apply',
      APPLICATION_VALIDATE_IBAN: '@api_accommodation:/applications/validate-iban',
      RESIDENCES: '@api_accommodation:/residences',
      RESIDENCES_ID: '@api_accommodation:/residences/:id',
      ROOMS_MAP_RESIDENCE_ID: '@api_accommodation:/residences/:id/rooms-map',
      RESIDENCES_PAYMENT_METHODS: '@api_accommodation:/residences/:id/payment-methods',
      ROOMS: '@api_accommodation:/rooms',
      REGIMES: '@api_accommodation:/regimes',
      EXTRAS: '@api_accommodation:/extras',
      RESIDENCE_EXTRAS: '@api_accommodation:/residence-extras',
      EXTENSIONS: '@api_accommodation:/extensions',
      EXTENSIONS_ID: '@api_accommodation:/extensions/:id',
      EXTENSIONS_STATUS: '@api_accommodation:/extensions/:id/status',
      ABSENCES: '@api_accommodation:/absences',
      ABSENCES_ID: '@api_accommodation:/absences/:id',
      ABSENCES_STATUS: '@api_accommodation:/absences/:id/status',
      WITHDRAWALS: '@api_accommodation:/withdrawals',
      WITHDRAWALS_ID: '@api_accommodation:/withdrawals/:id',
      WITHDRAWALS_STATUS: '@api_accommodation:/withdrawals/:id/status',
      STUDENT_APPROVE: '@api_accommodation:/applications/:id/student/approve-status',
      STUDENT_REJECT: '@api_accommodation:/applications/:id/student/reject-status',
      STUDENT_OPPOSE: '@api_accommodation:/applications/:id/student/oppose-status',
      STUDENT_CANCEL: '@api_accommodation:/applications/:id/student/cancel',
      BILLINGS: '@api_accommodation:/billings',
      DEPOSITS: '@api_accommodation:/deposits',

      EXTRA_CHANGE_REQUEST_STATUS: '@api_accommodation:/application-extra-changes/:id/status',
      EXTRA_CHANGE_REQUEST: '@api_accommodation:/application-extra-changes',
      EXTRA_CHANGE_REQUEST_ID: '@api_accommodation:/application-extra-changes/:id',
      REGIME_CHANGE_REQUEST_STATUS: '@api_accommodation:/application-regime-changes/:id/status',
      REGIME_CHANGE_REQUEST: '@api_accommodation:/application-regime-changes',
      REGIME_CHANGE_REQUEST_ID: '@api_accommodation:/application-regime-changes/:id',
      ROOM_CHANGE_REQUEST_STATUS: '@api_accommodation:/application-room-changes/:id/status',
      ROOM_CHANGE_REQUEST: '@api_accommodation:/application-room-changes',
      ROOM_CHANGE_REQUEST_ID: '@api_accommodation:/application-room-changes/:id',
      MAINTENANCE_REQUEST_STATUS: '@api_accommodation:/maintenance-requests/:id/status',
      MAINTENANCE_REQUEST: '@api_accommodation:/maintenance-requests',
      MAINTENANCE_REQUEST_ID: '@api_accommodation:/maintenance-requests/:id',
      TARIFF_REQUEST_STATUS: '@api_accommodation:/application-tariff-changes/:id/status',
      TARIFF_REQUEST: '@api_accommodation:/application-tariff-changes',
      TARIFF_REQUEST_ID: '@api_accommodation:/application-tariff-changes/:id',
      COMMUNICATION_REQUEST: '@api_accommodation:/application-communications',
      COMMUNICATION_REQUEST_ID: '@api_accommodation:/application-communications/:id',
      IBAN_CHANGE_REQUEST: '@api_accommodation:/application-iban-changes',
      IBAN_CHANGE_REQUEST_ID: '@api_accommodation:/application-iban-changes/:id',
      DIRECT_DEBIT_PREFILLED_AUTH: '@api_accommodation:/reports/direct-debit-prefilled-auth',
    },
    PAYMENTS: {
      PAYMENT_METHODS: '@api_payments:/payment-methods',
      PAYMENT_METHODS_ID: '@api_payments:/payment/methods/:id',
      CART: '@api_payments:/cart',
      CART_ITEM_QUANTITY: '@api_payments:/cart/changeQtdItem',
      CART_ID: '@api_payments:/cart/:id',
      CART_CHECKOUT: '@api_payments:/cart/checkout',
      CLEAR: '@api_payments:/cart/clearcart',
      REMOVE_ITEM: '@api_payments:/cart/removeItem',
      PAYMENTS: '@api_payments:/payments',
      PAYMENTS_ID: '@api_payments:/payments/:id',
    },
    CURRENT_ACCOUNT: {
      MOVEMENTS_BALANCES: '@api_current_account:/movements/balances',
      MULTI_ACCOUNTS: '@api_current_account:/accounts',
      MOVEMENTS_DETAILS: '@api_current_account:/movements',
      MOVEMENTS_USER_REPORT: '@api_current_account:/movements/user-report',
      MOVEMENTS_CHARGE_ACCOUNT: '@api_current_account:/movements/chargeAccount',
      PAY_MOVEMENT: '@api_current_account:/movements/pay',
      CHARGESHISTORY: '@api_current_account:/movements/chargesHistory',
      USER_REPORT_HISTORY_CHARGES: '@api_current_account:/movements/user-report-history-charges',
    },
    MEDIA: {
      UPLOAD: '@api_media_download:/',
      FILE_DOWNLOAD: '@api_media_download:/:path',
      FILE: '@api_media:/files',
      FILE_ID: '@api_media:/files/:id',
      FILE_UPLOAD: '@api_media:/files/upload'
    },
    ALIMENTATION_PACKS: {
      MENUS: '@api_alimentation:/menu_packs/service/:service_id/menus/:date/:meal',
      CHANGE_RESERVE: '@api_alimentation:/menu_packs/changeReserve'
    },
    ALIMENTATION: {
      ENTITIES: '@api_alimentation:/entities',
      MENUS: '@api_alimentation:/menu/service/:service_id/menus/:date/:meal',
      MENU_SERVICES: '@api_alimentation:/menu/:school_id/services',
      MENU_SCHOOLS: '@api_alimentation:/menu/schools',
      MENU_DISH: '@api_alimentation:/menu/service/:service_id/dish/:MenuDish_id',
      USER_ALLERGENS: '@api_alimentation:/menu/user-config/allergens',
      CANTEEN_PREDEFINED: '@api_alimentation:/menu/user-config/preferencial-canteen',
      PACK_CONFIG_GET: '@api_alimentation:/menu_packs/user-config/entities',
      PACK_CONFIG_POST: '@api_alimentation:/menu_packs/user-config/defaults',
      ENTITIES_PREDEFINED: '@api_alimentation:/menu_packs/user-config/entities',
      RESERVATIONS: '@api_alimentation:/menu/user/reservations',
      RESERVATIONS_COUNT: '@api_alimentation:/menu/user/reservations/count',
      RESERVATIONS_CANCEL: '@api_alimentation:/menu/user/reservations/:id/cancel',
      CART_ADD_ALIMENTATION: '@api_alimentation:/menu/cart/refectory/:id/add',
    },
    SOCIAL_SUPPORT: {
      APPLICATIONS: '@api_social_support:/applications',
      APPLICATIONS_ID: '@api_social_support:/applications/:id',
      APPLICATIONS_CERTIFICATE: '@api_social_support:/applications/:id/certificate',
      EXPERIENCES: '@api_social_support:/experiences',
      EXPERIENCES_USER_INTEREST: '@api_social_support:/experience-user-interest',
      EXPERIENCES_USER_INTEREST_ID: '@api_social_support:/experience-user-interest/:id',
      EXPERIENCES_ID: '@api_social_support:/experiences/:id',
      EXPERIENCE_ID_USER_INTERESTS: '@api_social_support:/experience-user-interest/experience/:id',
      EXPERIENCE_WITHDRAW_ID: '@api_social_support:/experience-user-interest/:id/status/interest-withdrawal',
      ATTENDANCES: '@api_social_support:/attendances',
      ATTENDANCES_ID_CHAGE_STATUS: '@api_social_support:/attendances/:id/change-status',
      ABSENCE_REASON: '@api_social_support:/absence_reason',
      ACTIVITIES: '@api_social_support:/activities',
      SCHEDULES: '@api_social_support:/schedules',
      SCHEDULES_ID: '@api_social_support:/schedules/:id',
      GENERAL_REPORTS_APPLICATIONS: '@api_social_support:/general-reports/user-interest/:id',
      MONTHLY_REPORTS_ID: '@api_social_support:/monthly-reports/:id',
      MONTHLY_REPORTS: '@api_social_support:/monthly-reports',
      MONTHLY_REPORTS_ID_ALERTS: '@api_social_support:/monthly-reports/:id/alerts',
      APPLICATION_STATUS: '@api_social_support:/applications/:id/status',
      APPLICATION_CANCEL: '@api_social_support:/applications/:id/status/application-cancel',
      APPLICATION_WITHDRAW: '@api_social_support:/applications/:id/status/applicant-withdraw',
      GENERAL_REPORTS_MONTHLY_STATISTICS: '@api_social_support:/general-reports/:user_id/monthly-statistics/:experience_id',
      CHECK_INTEREST: '@api_social_support:/experience-user-interest/check-interest/:id',
      USER_APPLICATION: '@api_social_support:/applications/user-application',
      STATUS_CHANGE_INTEREST: '@api_social_support:/experience-user-interest/:id/status',
      STATUS_CHANGE_INTEREST_CANCEL: '@api_social_support:/experience-user-interest/:id/status/interest-cancel',
      STATUS_CHANGE_SCHEDULE_ENTERVIEW: '@api_social_support:/experience-user-interest/:id/status/user-manifest-interview',
      STATUS_CHANGE_INTEREST_COMPLAIN: '@api_social_support:/complain_user_interests',
      GET_RESPONSIBLE: '@api_social_support:/experiences/responsable',
      GET_ADVISOR: '@api_social_support:/experiences/advisor',
      CANCEL_EXPERIENCE: '@api_social_support:/experiences/:id/status',
      EXPERIENCE_EVALUATION_ID: '@api_social_support:/experience-user-interest/:id/add-student-avaliation',
      APPLICATION_INTERVIEW: '@api_social_support:/applications/:id/status/applicant-interview',
      EXPERIENCES_USER_INTEREST_INTERVIEW_REPORT_ID: '@api_social_support:/experience-user-interest/:id/add-interview-report',
      PRINT_EXPERIENCE_ID: '@api_social_support:/experiences/:id/user-manifest-selection',
      GENERATE_COLLABORATION_CERTIFICATE: '@api_social_support:/experience-user-interest/:id/generate-certificate',
      ADD_COLLABORATION_CERTIFICATE: '@api_social_support:/experience-user-interest/:id/add-certificate',
      ADD_REPORT_EVALUATION: '@api_social_support:/experience-user-interest/:id/add-report-avaliation',
      MAX_MONTHLY_HOURS: '@api_social_support:/configurations/max-hours-work',
      EXTERNAL_ENTITIES: '@api_social_support:/external_entities',
      VALIDATE_ATTENDANCE_ID_JUSTIFICATION: '@api_social_support:/absence_reason/:id/accept',
      GENERAL_COMPLAINS: '@api_social_support:/general-complains',
      CONFIGURATIONS: '@api_social_support:/configurations',
      TERMS: '@api_social_support:/configurations/terms',
    },
    VOLUNTEERING: {
      APPLICATION_CANCEL: '@api_volunteering:/applications/:id/status/application-cancel',
      APPLICATIONS_ID: '@api_volunteering:/applications/:id',
      APPLICATIONS: '@api_volunteering:/applications',
      EXPERIENCES_ID: '@api_volunteering:/experiences/:id',
      EXPERIENCE_ID_USER_INTERESTS: '@api_volunteering:/experience-user-interests/experience/:id',
      EXPERIENCES_USER_INTEREST_ID: '@api_volunteering:/experience-user-interests/:id',
      EXPERIENCES_USER_INTEREST: '@api_volunteering:/experience-user-interests',
      EXPERIENCES: '@api_volunteering:/experiences',
      EXPERIENCE_WITHDRAW_ID: '@api_volunteering:/experience-user-interests/:id/status/interest-withdrawal',
      STATUS_CHANGE_INTEREST: '@api_volunteering:/experience-user-interests/:id/status',
      STATUS_CHANGE_INTEREST_CANCEL: '@api_volunteering:/experience-user-interests/:id/status/interest-cancel',
      STATUS_CHANGE_INTEREST_COMPLAIN: '@api_volunteering:/complain-user-interests',
      USER_APPLICATION: '@api_volunteering:/applications/user-application',
      CHECK_INTEREST: '@api_volunteering:/experience-user-interests/check-interest/:id',
      GET_RESPONSIBLE: '@api_volunteering:/experiences/responsable',
      CONFIGURATIONS: '@api_volunteering:/configurations',
      GET_ADVISOR: '@api_volunteering:/experiences/advisor',
      CANCEL_EXPERIENCE_ID: '@api_volunteering:/experiences/:id/status',
      PRINT_EXPERIENCE_ID: '@api_volunteering:/experiences/:id/user-manifest-selection',
      APPLICATIONS_CERTIFICATE: '@api_volunteering:/applications/:id/certificate',
      GENERATE_COLLABORATION_CERTIFICATE: '@api_volunteering:/experience-user-interests/:id/generate-certificate',
      REPORT_USER_INTERESTS_BY_COLABORATION:
      '@api_volunteering:/experience-user-interests/user-interest-by-colaboration-report',
      ADD_COLLABORATION_CERTIFICATE: '@api_volunteering:/experience-user-interests/:id/add-certificate',
      ATTENDANCES: '@api_volunteering:/attendances',
      ATTENDANCES_UPDATE: '@api_volunteering:/attendances/:id',
      ABSENCE_REASON: '@api_volunteering:/absence_reason',
      ATTENDANCES_ID_CHAGE_STATUS: '@api_volunteering:/attendances/:id/change-status',
      APPLICATION_STATUS: '@api_volunteering:/applications/:id/status',
      APPLICATION_WITHDRAW: '@api_volunteering:/applications/:id/status/applicant-withdraw',
      MONTHLY_REPORTS_ID: '@api_volunteering:/monthly-reports/:id',
      MONTHLY_REPORTS: '@api_volunteering:/monthly-reports',
      MONTHLY_REPORTS_ID_ALERTS: '@api_volunteering:/monthly-reports/:id/alerts',
      GENERAL_REPORTS_APPLICATIONS: '@api_volunteering:/general-reports/user-interest/:id',
      EXPERIENCE_EVALUATION_ID: '@api_volunteering:/experience-user-interests/:id/add-student-avaliation',
      ADD_REPORT_EVALUATION: '@api_volunteering:/experience-user-interests/:id/add-report-avaliation',
      EXTERNAL_ENTITIES: '@api_volunteering:/external_entities',
      VALIDATE_ATTENDANCE_ID_JUSTIFICATION: '@api_volunteering:/absence_reason/:id/accept',
      GENERAL_COMPLAINS: '@api_volunteering:/general-complains',
      GET_USER_COMPLAINTS: '@api_volunteering:/experience-user-interests/get-all-complains'

    },
    U_BIKE: {
      APPLICATIONS: '@api_u_bike:/applications',
      APPLICATIONS_ID: '@api_u_bike:/applications/:id',
      APPLICATIONS_DASHBOARD: '@api_u_bike:/applications/dashboard',
      TYPOLOGIES: '@api_u_bike:/typologies',

      APPLICATION_REJECT: '@api_u_bike:/applications/:id/reject',
      APPLICATION_APPROVE: '@api_u_bike:/applications/:id/approve',
      APPLICATION_WITHDRAW: '@api_u_bike:/applications/:id/withdraw',

      CONFIGURATIONS_BY_KEY: '@api_u_bike:/configs/:key',
      TERMS: '@api_u_bike:/configs/terms',

      TRIPS: '@api_u_bike:/trips',
      TRIPS_ID: '@api_u_bike:/trips/:id',
      MAINTENANCES: '@api_u_bike:/maintenances',
      MAINTENANCES_ID: '@api_u_bike:/maintenances/:id',

      REGULAMENTATIONS: '@api_u_bike:/configs/regulamentations',

      APPLICATION_CLIENT: '@api_u_bike:/applications/client/:id/status',
      APPLICATIONS_FORMS: '@api_u_bike:/application-forms',
      APPLICATIONS_FORMS_ID: '@api_u_bike:/application-forms/:id',
      APPLICATION_CAN_APLAY: '@api_u_bike:/applications/canApply',
      OTHER_TYPES: '@api_u_bike:/stats/saving-co2',
      APPLICATIONS_ELABORATION: '@api_u_bike:/applications/verify_elaboration',
      GET_CONSENT_TERMS_FILE: '@api_u_bike:/applications/:id/consent-terms',
      GET_DELIVERY_RECEPTION_FILE: '@api_u_bike:/applications/:id/delivery-reception',
      SUBMIT_CONSENT_DELIVERY_REPORTS: '@api_u_bike:/applications/:id/submit-reports'
    },
    PRIVATE_ACCOMMODATION: {
      OWNERS: '@api_private_accommodation:/owners',
      OWNERS_APPLICATIONS: '@api_private_accommodation:/owners/application',
      CHECK_PROPERTIES: '@api_private_accommodation:/listings/check_properties',
      LISTINGS: '@api_private_accommodation:/listings',
      LISTINGS_SEARCH: '@api_private_accommodation:/listings/search',
      LISTINGS_LOCATION: '@api_private_accommodation:/listings/locations',
      LISTINGS_OWNER: '@api_private_accommodation:/listings/owner',
      LISTINGS_PRICE_INTERVAL: '@api_private_accommodation:/listings/price-min-max',
      LISTINGS_ID: '@api_private_accommodation:/listings/:id',
      LISTINGS_INCREMENT: '@api_private_accommodation:/listings/:id/increment',
      COMPLAINTS: '@api_private_accommodation:/complaints',
      TYPOLOGIES: '@api_private_accommodation:/typologies',
      CONFIG_MAX_MEDIAS: '@api_private_accommodation:/configurations/max_medias_per_listing',
      CONFIG_MAX_PUBLISH_DAYS: '@api_private_accommodation:/configurations/max_listing_publish_days',
      CONFIG_OWNER_REGULATION: '@api_private_accommodation:/configurations/owner_regulation',
      CONFIG_OWNER_PROFILE: '@api_private_accommodation:/configurations/owner_profile_id',
      CONFIG_IS_CONFIGURED: '@api_private_accommodation:/configurations/is_configured'
    },
    INFRASTRUCTURE: {
      ORGANIC_UNITS: '@api_infrastructure:/organic-units',
      BUILDINGS: '@api_infrastructure:/buildings',
    },
    MOBILITY: {
      LOCALS: '@api_mobility:/locals',
      LOCALS_INSTITUTE: '@api_mobility:/locals',
      ROUTE_SEARCH: '@api_mobility:/route_search',
      ADD_CART: '@api_mobility:/route_search/addCart',
      BUY_TICKET: '@api_mobility:/route_search/buyTicket',
      APPLICATIONS: '@api_mobility:/applications',
      APPLICATIONS_ID: '@api_mobility:/applications/:id',
      APPLICATION_CANCEL_ID: '@api_mobility:/applications/:id/status',
      APPLICATION_WITHDRAWAL_ID: '@api_mobility:/applications/withdrawal/:id',
      APPLICATION_RESUME_ID: '@api_mobility:/applications/:id/resume',
      TICKETS_BOUGHT: '@api_mobility:/tickets_bought',
      TICKETS_BOUGHT_ID: '@api_mobility:/tickets_bought/:id',
      TICKETS_BOUGHT_VALIDATE: '@api_mobility:/tickets_bought/validate',
      WITHDRAWALS: '@api_mobility:/withdrawals',
      CONFIGURATIONS_BY_KEY: '@api_mobility:/configurations/:key',

      SUB23_DECLARATIONS: '@api_mobility:/sub23_declarations',
      SUB23_DECLARATIONS_ID: '@api_mobility:/sub23_declarations/:id',
      SUB23_DECLARATIONS_STATUS: '@api_mobility:/sub23_declarations/:id/status',

      ABSENCES: '@api_mobility:/absences',
      ABSENCES_ID: '@api_mobility:/absences/:id',
    },
    EMERGENCY_FUND: {
      APPLICATIONS: "@api_emergency_fund:/applications",
      APPLICATION_CHANGE_STATUS: "@api_emergency_fund:/applications/:id/status",
      APPLICATIONS_ID: "@api_emergency_fund:/applications/:id",
      INCOMES_LIST: "@api_emergency_fund:/incomes",
      DOCUMENTS_LIST:
        "@api_emergency_fund:/document_types/:id/get-all-document_types-by-category",
      SUPPORT_LIST: "@api_emergency_fund:/supports",
      INTENDED_SUPPORT_LIST: "@api_emergency_fund:/intended_supports/:id/get-all-intended-supports-by-application",
      APPLICATIONS_VERIFY_ACTIVE:
        "@api_emergency_fund:/applications/user-application",
      ADD_COMPLAIN: "@api_emergency_fund:/applications/add-complain",
      ADD_COMPLAIN_DOC: "@api_emergency_fund:/documents",
      ADD_COMPLAIN_REPORT:
        "@api_emergency_fund:/applications/add-complain-report",
      GET_COMPLAIN_BY_APP_ID: "@api_emergency_fund:/complains/:id/get-all-complains-by-application",
      ADD_APPLICATION_CHANGE:
        "@api_emergency_fund:/application_changes/add-application_change",
      APPLICATION_CHANGE_LIST: "@api_emergency_fund:/application_changes",
      APPLICATION_CHANGE_CHANGE_STATUS: "@api_emergency_fund:/application_changes/status",
      APPLICATION_CHANGE_LIST_ID: "@api_emergency_fund:/application_changes/:id/get-all-application-changes-by-application",
      APPLICATION_CHANGE_REVIEW_ID: "@api_emergency_fund:/application_changes/:id",
      ADD_APPLICATION_CHANGE_DOCUMENT:
        "@api_emergency_fund:/application_changes/add-application-change-document",
      EXPENSES: "@api_emergency_fund:/expenses",
      LIST_EXPENSES: "@api_emergency_fund:/expenses/:id/get-all-expenses-by-application",
      GET_EXPENSES: "@api_emergency_fund:/expenses/:id",
      ADD_EXPENSE: "@api_emergency_fund:/expenses/add-expense",
      ADD_EXPENSE_DOC: "@api_emergency_fund:/expenses/add-expense-document",

    },
    HEALTH: {
      SPECIALTIES : '@api_health:/specialty',
      SPECIALTY_ID: '@api_health:/specialty/:id',
      SPECIALTY_SCHEDULE_DOCTOR: '@api_health:/specialty/:id/schedule',
      APPOINTMENTS_HISTORIC: '@api_health:/appointment/historic',
      CREATE_APPOINTMENT: '@api_health:/appointment',
      LIST_APPOINTMENT_USER: '@api_health:/appointment/list-by-user',
      APPOINTMENTS_HISTORIC_FILTERS: '@api_health:/appointment/appointments-historic-filters',
      APPOINTMENTS_HISTORIC_AUTOCOMPLETE: '@api_health:/appointment/appointments-historic-autocomplete',
      APPOINTMENT_DETAIL: '@api_health:/appointment/:id',
      CHANGE_APPOINTMENT: '@api_health:/appointment/:id/change',
      CANCEL_APPOINTMENT: '@api_health:/appointment/:id/cancel',
      STUDENT_UPDATE_PRE_CONFIRMATION: '@api_health:/appointment/:id/student_confirmation',
      ABSENCE_APPOINTMENT: '@api_health:/appointment/:id/change-status',
      GENERATE_CERTIFICATE: '@api_health:/appointment/:id/certificate',
      REMOVE_ABSENCE_APPOINTMENT: '@api_health:/appointment/:id/change-status',
      HISTORIC_APPOINTMENT: '@api_health:/appointment/:id/historic',
      ATTENDANCE_INFORMATION: '@api_health:/appointment/:id/attendance-information',
      START_ATTENDANCE: '@api_health:/appointment/:id/start-attendance',
      CLOSE_ATTENDANCE: '@api_health:/appointment/:id/close-attendance',
      APPOINTMENT_ATTENDANCE_CERTIFICATE: '@api_health:/appointment/:id/certificate',
      DOCTOR : '@api_health:/doctor',
      DOCTOR_APPOINTMENTS_OLDER_OPEN: '@api_health:/appointment/doctor_appointments_older_open',
      DOCTOR_APPOINTMENTS_OPEN: '@api_health:/appointment/doctor_appointments_open',
      DOCTOR_APPOINTMENTS_CLOSE: '@api_health:/appointment/doctor_appointments_close',
      DOCTOR_SPECIALTIES: '@api_health:/doctor/:id/specialties',
      IS_PROFESSIONAL: '@api_health:/doctor/isProfessional',
      DOCTOR_INFO: '@api_health:/doctor/info',
      APPOINTMENT_TYPES:  '@api_health:/appointment-type',
      TARIFFS: '@api_health:/tariff',
      CREATE_COMPLAINT: '@api_health:/appointment/:id/complaint',
      GET_USER_COMPLAINTS: '@api_health:/complaint/user-complaints',
      CALENDAR_EXPORT: '@api_health:/appointment/:id/export_calendar',
      HEALTH_PLAN_USER_CAN_APPLY: '@api_health:/health-plan-user/can_apply',
      HEALTH_PLANS_USER: '@api_health:/health-plan-user/',
      HEALTH_PLAN_AVAILABLE: '@api_health:/health-plan/currentPlan',
      HEALTH_PLAN_ID: '@api_health:/health-plan/:id',
      HEALTH_PLAN_USER_ID: '@api_health:/health-plan-user/:id',
      HEALTH_PLAN_USER_AVAILABLE: '@api_health:/health-plan-user/has_health_plan_active',
    },

    REQUESTS:{
      DGES_REQUESTS: '@api_requests:/dges-requests',
      DGES_REQUESTS_ID: '@api_requests:/dges-requests/:id',
    },

    REFILL_H2O: {
      MENU: {
        MY: '@api_refill_h2o:/menu/my',
        PRODUCTS: '@api_refill_h2o:/menu/products',
        ADD_CART: '@api_refill_h2o:/menu/:id/add',
      }
    },

    MAINTENANCE: {
      MAINTENANCE_REQUEST: '@api_maintenance:/maintenance_requests',
      MAINTENANCE_SERVICES: '@api_maintenance:/maintenance_requests/services',
      MAINTENANCE_RESIDENCIES: '@api_maintenance:/maintenance_requests/residences',
      MAINTENANCE_HISTORIC_STATUS_BY_ID: '@api_maintenance:/maintenance_requests/:id',
      CHANGE_STATUS: '@api_maintenance:/maintenance_requests/:id/status',
    },

    JOB_PORTAL: {
      QR_CODE_EMPLOYMENT_FAIR: '@api_job_portal:/employment_events/:uuid/external',
      CANDIDATES_BY_USER_ID: '@api_job_portal:/candidates/profile',
      CANDIDATES: '@api_job_portal:/candidates',
      CANDIDATES_ID: '@api_job_portal:/candidates/:id',
      CANDIDATES_SAVE_ALL_DOCUMENTS: '@api_job_portal:/candidate_documents/updateFilesCandidate',
      CANDIDATE_ACADEMIC_INFO: '@api_job_portal:/candidate_academic_info',
      CANDIDATE_OTHER_INFO: '@api_job_portal:/candidate_other_informations',
      CANDIDATE_UPDATE_OTHER_INFO: '@api_job_portal:/candidate_other_informations/updateAllInformations',
      CANDIDATE_OTHER_INFO_ID: '@api_job_portal:/candidate_other_informations/:id',
      CANDIDATE_ACADEMIC_INFO_ID: '@api_job_portal:/candidate_academic_info/:id',
      CANDIDATE_PROFESSIONAL_EXPERIENCE: '@api_job_portal:/candidate_professional_experience',
      CANDIDATE_PROFESSIONAL_EXPERIENCE_ID: '@api_job_portal:/candidate_professional_experience/:id',
      CANDIDATE_IDIOMS: '@api_job_portal:/candidate_idioms',
      CANDIDATE_IDIOMS_ID: '@api_job_portal:/candidate_idioms/:id',
      CANDIDATE_UPDATE_ALL_ACADEMIC_INFO: '@api_job_portal:/candidate_academic_info/updateAllAcademicInfo',
      CANDIDATE_UPDATE_ALL_EXPERIENCE: '@api_job_portal:/candidate_professional_experience/updateAllExperience',
      CANDIDATE_UPDATE_ALL_IDIOMS:'@api_job_portal:/candidate_idioms/updateAllIdioms',
      CONFIGURATIONS: '@api_job_portal:/configurations',
      WORK_MODALITY : '@api_job_portal:/work_modality',
      ESPECTATIONS_ABOUT_WORK : '@api_job_portal:/expectations_about_work',
      TYPE_HORARY : '@api_job_portal:/horary_type',
      LITERARY_SKILLS : '@api_job_portal:/literary_skills',
      PROFESSIONS: '@api_job_portal:/professions/node',
      SUB_SECTORS: '@api_job_portal:/sectors/sub-sector',
      FILTER_PROFESSIONS_JOB: '@api_job_portal:/professions/jobOffers',
      FILTER_PROFESSIONS_INTERNSHIP: '@api_job_portal:/professions/internshipOffers',
      PROFESSIONAL_EXPERIENCE : '@api_job_portal:/professional_experience',
      SALARY_TIER : '@api_job_portal:/salary_tier',
      INTERNSHIP_TYPES : '@api_job_portal:/internship_type',
      CONTRACT_TYPE: '@api_job_portal:/contract_type',
      APPLICATIONS: '@api_job_portal:/applications',
      APPLICATIONS_ID: '@api_job_portal:/applications/:id',
      DASHBOARD: '@api_job_portal:/applications/candidatesDashboards',
      CURRENT_CANDIDATE: '@api_job_portal:/candidates/current',
      COMPANIES: {
        LIST: '@api_job_portal:/companies',
        GET_BY_ID: '@api_job_portal:/companies/:id ',
      },
      OFFERS: {
        LIST: '@api_job_portal:/offers',
        GET_BY_ID: '@api_job_portal:/offers/:id',
        ADD_FAVORITE_OFFER: '@api_job_portal:/candidate_offer_favorite',
        REMOVE_FAVORITE_OFFER: '@api_job_portal:/candidate_offer_favorite/delete/:offer_id'
      },
      EMPLOYMENT_EVENTS: {
        LIST: '@api_job_portal:/employment_events',
      },
      INTERNSHIP_OFFERS: {
        GET_BY_ID: '@api_job_portal:/internship_offers/:id',
      }
    }
  },
  LANGUAGES: [
    {
      acronym: 'pt',
      id: 3
    },
    {
      acronym: 'en',
      id: 4
    }
  ],
  ERROR_TRANSLATOR: {
    NOT_FOUND_MESSAGE: 'Ocorreu um erro desconhecido',
    REFRESH_PAGE: 'Ocorreu um erro, por favor actualize a página',
    SERVICE_DOWN: 'Serviço temporáriamente indisponível'
  }
};

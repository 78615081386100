<nz-spin [nzSpinning]="working">
  <div>
    <div nz-row nzType="flex" nzAlign="middle" nzJustify="center">
      <div nz-col nzSpan="24" style="text-align: center;">
        <i style="font-size: 120px" nz-icon nzType="disconnect"></i>
      </div>
    </div>
    <h3 style="text-align: center;">Sem acesso a internet</h3>
    <p style="text-align: center;">Por favor verifique a ligação a rede</p>
  </div>
</nz-spin>
<div class="report">
  <button nz-button nzType="link" nz-popover [nzPopoverTitle]="'REPORTS.TITLE' | translate"
    [(nzPopoverVisible)]="visibleReports" (nzPopoverVisibleChange)="change($event)" nzPopoverTrigger="click"
    [nzPopoverContent]="contentTemplate" class="reports-button">
    <nz-badge [nzCount]="total" [nzOverflowCount]="99">
      <i nz-icon nzType="file" style="font-size:18px" nzTheme="outline" class="header-icon"></i>
    </nz-badge>

  </button>
</div>
<ng-template #contentTemplate>
  <div *ngIf="!loading">
    <nz-list [nzDataSource]="$reports | async" [nzRenderItem]="defaultItem"
      style="height: 300px; overflow-y: scroll; width: 270px;">
      <ng-template #defaultItem let-report>
        <div nz-row style="padding-top: 8px;">
          <div nz-col [nzXs]="24" [nzMd]="4" [nzLg]="3">
            <i *ngIf="report.status === 'READY'" [nzTooltipTitle]="'REPORTS.READY' | translate" nz-tooltip
              class="icon-file" nz-icon nzType="icons:icons-news" id="icon-report"></i>
            <i *ngIf="report.status === 'FAILED'" [nzTooltipTitle]="'REPORTS.FAILED' | translate" nz-tooltip
              class="icon" nz-icon nzType="icons:icons-icons-alert"></i>
            <i *ngIf="report.status === 'PROCCESSING'" [nzTooltipTitle]="'REPORTS.PROCESSING' | translate" nz-tooltip
              class="icon" nz-icon nzType="sync" nzSpin="true" id="icon-report"></i>
            <i *ngIf="report.status === 'WAITING'" nz-icon nzType="exclamation-circle" nz-tooltip
              [nzTooltipTitle]="'REPORTS.WAIT' | translate" nzTheme="outline" class="icon" id="waiting-report"></i>
          </div>
          <div nz-col [nzXs]="24" [nzMd]="17" [nzLg]="18" style="padding-bottom: 10px;">
            <span class="text-title" [ngClass]="{'bold': report.unread}"> {{ report?.name }}</span>
            <br>
            <span class="description">{{report?.created_at | date: "dd-MM-yyyy"}} - {{report?.created_at | date:
              "HH:mm"}}h</span>
            <br>
            <button class="download-button" nz-button nzSize="small" (click)="download(report)"
              [disabled]="report.status !== 'READY'">{{'REPORTS.DOWNLOAD' |
              translate}}</button>
          </div>
          <div nz-col [nzXs]="24" [nzMd]="3" [nzLg]="2">
            <i nz-icon nzType="close" nzTheme="outline" nz-tooltip [nzTooltipTitle]="'MISC.DELETE' | translate"
              (click)="delete(report.id)" class="icon-delete"></i>
          </div>
          <nz-divider style="margin:0px!important;"></nz-divider>
        </div>
      </ng-template>
    </nz-list>
  </div>
  <div *ngIf="loading" nz-row class="padding-loading">
    <nz-spin [nzSpinning]="loading">

    </nz-spin>
  </div>

  <div nz-row class="border-buttons">
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="11" [nzLg]="11" [nzXl]="11" [nzXXl]="11" class="button-more">
      <button nz-button class="button ant-btn ant-btn-default ant-btn-round" (click)="showMore()"
        [disabled]="!(($reportsLength | async) != $reports.source._value.length)">{{'REPORTS.SHOWMORE' |
        translate}}</button>
    </div>
    <div nz-col [nzXs]="0" [nzSm]="0" [nzMd]="2" [nzLg]="2" [nzXl]="2" [nzXXl]="2">

    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="11" [nzLg]="11" [nzXl]="11" [nzXXl]="11" class="space-button">
      <button nz-button class="ant-btn ant-btn-default ant-btn-round button" (click)="refresh();">{{'REPORTS.REFRESH' |
        translate}}</button>
    </div>
  </div>

</ng-template>
import { NgModule } from '@angular/core';
import {
  FiConfigurator,
  OPTIONS_TOKEN,
  DEFAULT_CONFIG_OPTIONS,
  Options
} from './configurator.service';

export function configuratorFactory(options: Options) {
  return new FiConfigurator(options);
}

@NgModule({
  providers: [
    { provide: OPTIONS_TOKEN, useValue: DEFAULT_CONFIG_OPTIONS },
    {
      provide: FiConfigurator,
      useFactory: configuratorFactory,
      deps: [OPTIONS_TOKEN]
    }
  ]
})
export class FiConfiguratorModule {}

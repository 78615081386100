import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UiService } from '../../services/ui.service';
import { SiderItem } from '../../models/sider-item';
import { SidebarOptionsChangeService } from '../../services/sidebar-options-change.service';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { ConfigurationsService } from '@fi-sas/webpage/shared/services/configurations.service';
import { AuthService } from '@fi-sas/webpage/auth/services/auth.service';
import { ReservationCountService } from '../../services/reservation-count.service';
import { Router } from '@angular/router';
import { FiConfigurator } from '@fi-sas/configurator';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-layout-mobile-menu',
  templateUrl: './layout-mobile-menu.component.html',
  styleUrls: ['./layout-mobile-menu.component.less']
})
export class LayoutMobileMenuComponent implements OnInit {

  public $siderDrawerCollapsed = null;
  placement: NzDrawerPlacement = 'left';
  siderItems: SiderItem[] = null;
  dataConfiguration;
  total = 0;
  externalServicesConfig = [];
  externalServices = [];
  languages: any;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private configurationsService: ConfigurationsService,
    private reservationCountService: ReservationCountService,
    private sidebarOptionsChange: SidebarOptionsChangeService,
    private uiService: UiService,
    public translate: TranslateService,
    private router: Router,
    private config: FiConfigurator,
  ) {

    this.reservationCountService.total$.subscribe(
      (total) => {
        this.total = total;
      }
    );
    this.$siderDrawerCollapsed = this.uiService.siderDrawerObservable();
    this.languages = Object.values(config.getOptionTree('LANGUAGES'))[0];
  }

  ngOnInit() {
    this.dataConfiguration = this.configurationsService.getLocalStorageTotalConfiguration();
    this.uiService.getSiderItemsObservable().subscribe(value => {
      this.validaService(value);
      //this.siderItems = value.filter(si => !si.disable);
      // this.sidebarOptionsChange.optionsChange();
    });
    this.addExternalServices();
  }


  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  closeSideMenu() {
    this.uiService.triggerDrawerSider();
  }


  langChange(lang) {
    this.translate.use(lang);
  }

  validaService(_value: SiderItem[]) {
    let logged = this.authService.getIsLogged();
    this.siderItems = Object.assign([], this.uiService.getSiderItems());
    if (logged) {
      this.siderItems = this.siderItems.filter((fil) => this.dataConfiguration.find(service => service.id == fil.serviceId && service.active == true && this.authService.hasPermission(fil.scope as string)));
    } else {
      this.siderItems = this.siderItems.filter((fil) => this.dataConfiguration.find(service => service.id == fil.serviceId && service.active == true));
    }

    this.siderItems.filter((fil) => {
      fil.name = (this.dataConfiguration.find((service) => service.id == fil.serviceId)) ? (this.dataConfiguration.find((service) => service.id == fil.serviceId).translations) : '';
    })

    this.siderItems.forEach((item) => {
      item.children.forEach((child) => {
        if (!child.scope) {
          child.visible = true;
          return;
        }
        if (typeof child.scope === "string") {
          child.visible = this.authService.hasPermission(child.scope);
          return;
        }
        child.visible = child.scope.every((scope) => this.authService.hasPermission(scope));
      });
    });
  }

  addExternalServices() {
    this.externalServicesConfig = this.dataConfiguration.filter(data => data.is_external_service && data.active && data.webpage_availability);
    this.externalServicesConfig.forEach((external) => this.externalServices.push({
      name: external.translations,
      link: external.external_link,
      icon: external.icon ? 'icons:' + external.icon : 'icons:icons-external',
      profiles: external.profiles,
      active: true,
      external: true
    }));

    const user = this.authService.getUser();
    if (user) {
      //this.externalServices.sort((a, b) =>a.name.find(nA => nA.language_id === this.getCurrentLanguage()).description > b.name.find(nB => nB.language_id === this.getCurrentLanguage()).description ? 1 : -1 );

      this.externalServices.sort((a, b) => {
        const nameA = a.name.find((nA) => nA.language_id === this.getCurrentLanguage()) ? a.name.find((nA) => nA.language_id === this.getCurrentLanguage()).description : null;
        const nameB = b.name.find((nB) => nB.language_id === this.getCurrentLanguage()) ? b.name.find((nB) => nB.language_id === this.getCurrentLanguage()).description : null;
        return nameA && nameB ? nameA > nameB ? 1 : -1 : -1;
      });
      this.externalServices.forEach((external) => {
        if (external.profiles.length) {
          if (external.profiles.find(p => p.profile_id === user.profile_id)) {
            this.siderItems.push(external);
          }
        } else {
          this.siderItems.push(external);
        }
      });
    }
  }


  selectModule(module) {
    if (!module.external) {
      this.router.navigate([module.link]);
    } else {
      window.open(module.link, "_blank");
    }
  }

  getCurrentLanguage() {
    const currentLang = this.languages.find(lang => lang.acronym === this.translate.currentLang);
    return currentLang.id;
  }

}



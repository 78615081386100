import { AuthService } from "@fi-sas/webpage/auth/services/auth.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpParams } from "@angular/common/http";

import { FiResourceService, FiUrlService, Resource } from "@fi-sas/core";
import { BalanceModel } from "@fi-sas/webpage/modules/current-account/models/balance.model";
import { first, finalize } from "rxjs/operators";
import { MovementsModel, PaymentDataModel } from "@fi-sas/webpage/modules/current-account/models/movements.model";
import * as moment from "moment";

import { ChargeAccountModel } from "../models/charge-account.model";
import { PayMovementModel } from "../models/pay-movement.model";
import { PaymentMethodModel } from "../models/payment-method.model";

@Injectable({
  providedIn: "root",
})
export class MovementsService {
  balancesData = new BehaviorSubject([]);
  balancesExpireDate = null;
  balancesLoading = false;

  constructor(
    private resourceService: FiResourceService,
    private urlService: FiUrlService,
    private authService: AuthService
  ) {
    authService.getIsLoggedObservable().subscribe((isLogged) => {
      if (isLogged) {
        this.getBalances();
      } else {
        this.clearBalances();
      }
    });
  }

  private isBalancesUpdateExpired() {
    return (
      !this.balancesExpireDate ||
      moment(this.balancesExpireDate).diff(new Date(), "m") < -5
    );
  }

  balances(): Observable<Resource<BalanceModel>> {
    let params = new HttpParams();
    params = params.set("withRelated", "available_methods");
    return this.resourceService.list<BalanceModel>(
      this.urlService.get("CURRENT_ACCOUNT.MOVEMENTS_BALANCES", {}),
      { params }
    );
  }

  paymentsMethods(): Observable<Resource<PaymentMethodModel>> {
    return this.resourceService.list<PaymentMethodModel>(
      this.urlService.get("PAYMENTS.PAYMENT_METHODS", {}),
      {}
    );
  }

  clearBalances() {
    this.balancesExpireDate = null;
    this.balancesLoading = false;
    this.balancesData.next([]);
  }

  getBalances(force?: boolean): void {
    if ((this.isBalancesUpdateExpired() && !this.balancesLoading) || force) {
      this.balancesLoading = true;
      this.balances()
        .pipe(
          first(),
          finalize(() => (this.balancesLoading = false))
        )
        .subscribe((balances) => {
          this.balancesExpireDate = new Date();
          this.balancesData.next(balances.data);
        });
    }
  }

  balancesObservable(): Observable<BalanceModel[]> {
    return this.balancesData.asObservable();
  }

  balancesById(
    accountId: number,
    requestTotalCharges?: boolean
  ): Observable<Resource<BalanceModel>> {
    let params = new HttpParams();
    params = params.set("account_id", accountId.toString());
    params = params.set("withRelated", "available_methods");

    if (requestTotalCharges) {
      params = params.set("requestTotalCharges", "true");
    }

    return this.resourceService.list<BalanceModel>(
      this.urlService.get("CURRENT_ACCOUNT.MOVEMENTS_BALANCES"),
      {
        params,
      }
    );
  }

  listMovements(
    pageIndex: number,
    pageSize: number,
    sortKey?: string,
    sortValue?: string,
    statusFilter?: string,
    operationFilter?: string,
    withRelated?: string,
    accountId?: number,
    minDate?: string,
    maxDate?: string
  ): Observable<Resource<MovementsModel>> {
    let params = new HttpParams();
    params = params.set("offset", ((pageIndex - 1) * pageSize).toString());
    params = params.set("limit", pageSize.toString());
    params = statusFilter
      ? params.set("query[status]", statusFilter.toString())
      : params;
    params = operationFilter
      ? params.set("query[operation]", operationFilter.toString())
      : params;

    if (sortKey) {
      if (sortValue === "ascend") {
        params = params.set("sort", sortKey);
      } else {
        params = params.set("sort", "-" + sortKey);
      }
    } else {
      params = params.set("sort", "-created_at");
    }

    params = params.set("withRelated", withRelated.toString());
    params = params.set("query[account_id]", accountId.toString());

    minDate != "" && minDate != null
      ? (params = params.set("query[created_at][gte]", minDate))
      : params;
    maxDate != "" && maxDate != null
      ? (params = params.set("query[created_at][lte]", maxDate))
      : params;

    return this.resourceService.list<MovementsModel>(
      this.urlService.get("CURRENT_ACCOUNT.MOVEMENTS_DETAILS"),
      {
        params,
      }
    );
  }

  listReportMovements(
    pageIndex: number,
    pageSize: number,
    sortKey?: string,
    sortValue?: string,
    statusFilter?: string,
    operationFilter?: string,
    withRelated?: string,
    accountId?: number,
    minDate?: string,
    maxDate?: string
  ): Observable<Resource<MovementsModel>> {
    let params = new HttpParams();
    params = params.set("offset", ((pageIndex - 1) * pageSize).toString());
    params = params.set("limit", pageSize.toString());
    params = statusFilter
      ? params.set("query[status]", statusFilter.toString())
      : params;
    params = operationFilter
      ? params.set("query[operation]", operationFilter.toString())
      : params;

    if (sortKey) {
      if (sortValue === "ascend") {
        params = params.set("sort", sortKey);
      } else {
        params = params.set("sort", "-" + sortKey);
      }
    } else {
      params = params.set("sort", "-created_at");
    }

    params = params.set("withRelated", withRelated.toString());

    params = params.set("query[account_id]", accountId.toString());

    minDate != "" && minDate != null
      ? (params = params.set("query[created_at][gte]", minDate))
      : params;

    maxDate != "" && maxDate != null
      ? (params = params.set("query[created_at][lte]", maxDate))
      : params;

    return this.resourceService.list<MovementsModel>(
      this.urlService.get("CURRENT_ACCOUNT.MOVEMENTS_USER_REPORT"),
      {
        params,
      }
    );
  }

  chargeAccount(
    sendValue: ChargeAccountModel
  ): Observable<Resource<MovementsModel>> {
    return this.resourceService.create<MovementsModel>(
      this.urlService.get("CURRENT_ACCOUNT.MOVEMENTS_CHARGE_ACCOUNT", {}),
      sendValue
    );
  }

  payMovement(
    sendValue: PayMovementModel
  ): Observable<Resource<MovementsModel>> {
    return this.resourceService.create<MovementsModel>(
      this.urlService.get("CURRENT_ACCOUNT.PAY_MOVEMENT", {}),
      sendValue
    );
  }

  //------------------------HISTORY DEPOSITS---------------------------------

  listHistoryDeposits(
    pageIndex: number,
    pageSize: number,
    sortKey?: string,
    sortValue?: string,
    statusFilter?: string,
    operationFilter?: string,
    withRelated?: string,
    accountId?: number,
    minDate?: string,
    maxDate?: string
  ): Observable<Resource<MovementsModel>> {
    let params = new HttpParams();
    params = params.set("offset", ((pageIndex - 1) * pageSize).toString());
    params = params.set("limit", pageSize.toString());
    params = statusFilter
      ? params.set("query[status]", statusFilter.toString())
      : params;
    params = operationFilter
      ? params.set("query[operation]", operationFilter.toString())
      : params;

    if (sortKey) {
      if (sortValue === "ascend") {
        params = params.set("sort", sortKey);
      } else {
        params = params.set("sort", "-" + sortKey);
      }
    } else {
      params = params.set("sort", "-created_at");
    }

    params = params.set("withRelated", withRelated.toString());
    params = params.set("query[account_id]", accountId.toString());

    minDate != "" && minDate != null
      ? (params = params.set("query[created_at][gte]", minDate))
      : params;
    maxDate != "" && maxDate != null
      ? (params = params.set("query[created_at][lte]", maxDate))
      : params;

    return this.resourceService.list<MovementsModel>(
      this.urlService.get("CURRENT_ACCOUNT.CHARGESHISTORY"),
      {
        params,
      }
    );
  }

  listReportHistoryDeposits(
    pageIndex: number,
    pageSize: number,
    sortKey?: string,
    sortValue?: string,
    statusFilter?: string,
    operationFilter?: string,
    withRelated?: string,
    accountId?: number,
    minDate?: string,
    maxDate?: string
  ): Observable<Resource<MovementsModel>> {
    let params = new HttpParams();
    params = params.set("offset", ((pageIndex - 1) * pageSize).toString());
    params = params.set("limit", pageSize.toString());
    params = statusFilter
      ? params.set("query[status]", statusFilter.toString())
      : params;
    params = operationFilter
      ? params.set("query[operation]", operationFilter.toString())
      : params;

    if (sortKey) {
      if (sortValue === "ascend") {
        params = params.set("sort", sortKey);
      } else {
        params = params.set("sort", "-" + sortKey);
      }
    } else {
      params = params.set("sort", "-created_at");
    }

    params = params.set("withRelated", withRelated.toString());

    params = params.set("query[account_id]", accountId.toString());

    minDate != "" && minDate != null
      ? (params = params.set("query[created_at][gte]", minDate))
      : params;

    maxDate != "" && maxDate != null
      ? (params = params.set("query[created_at][lte]", maxDate))
      : params;

    return this.resourceService.list<MovementsModel>(
      this.urlService.get("CURRENT_ACCOUNT.USER_REPORT_HISTORY_CHARGES"),
      {
        params,
      }
    );
  }

  getPayment(id: string): Observable<Resource<PaymentDataModel>> {
    return this.resourceService.read<PaymentDataModel>(
      this.urlService.get("PAYMENTS.PAYMENTS_ID", { id }),
      {
      }
    );
  }
}

import { UiService } from './../../services/ui.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-template',
  templateUrl: './full-template.component.html',
  styleUrls: ['./full-template.component.less']
})
export class FullTemplateComponent implements OnInit {
  public $siderCollapsed = null;

  constructor(private uiService: UiService) {
    this.$siderCollapsed = this.uiService.siderObservable();
  }

  ngOnInit() {}

  tiggerSider() {
    this.uiService.triggerSider();
  }
}

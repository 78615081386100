
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FiUrlService, FiResourceService, Resource } from '@fi-sas/core';
import { AuthService } from '@fi-sas/webpage/auth/services/auth.service';
import { BalanceModel } from '@fi-sas/webpage/modules/current-account/models/balance.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class CurrentAccountsService {
  currentAccounts: BalanceModel[] = [];
  currentAccountsData = new BehaviorSubject([]);

  constructor(private resourceService: FiResourceService,
    private urlService: FiUrlService, private authService: AuthService) {
    this.authService.getIsLoggedObservable().subscribe((isLogged) => {
      if (!isLogged) {
        this.currentAccounts = [];
        this.currentAccountsData.next(this.currentAccounts);
      } else {
        this.loadData();
      }
    })
  }

  list(): Observable<Resource<BalanceModel>> {
    return this.resourceService.list<BalanceModel>(this.urlService.get('CURRENT_ACCOUNT.MOVEMENTS_BALANCES', {}));
  }

  loadData() {
    this.list().pipe(
      first()
    )
      .subscribe((result) => {
        this.currentAccounts = result.data;
        this.currentAccountsData.next(this.currentAccounts);
      });
  }

  getCurrentAccounts() {
    return this.currentAccounts;
  }

  getCurrentAccountObservable(): Observable<object> {
    return this.currentAccountsData.asObservable();
  }

}
